const siteAdapter = s => {
  if (s.id && s.attributes) {
    return {
      id: s.id,
      ...s.attributes,
      relationships: {
        ...s.relationships,
        sspSync: s?.relationships
          ? [
              ...(s.relationships.improve_sync
                ? [
                    {
                      ...s.relationships.improve_sync,
                      sspName: 'Improve',
                    },
                  ]
                : []),
            ]
          : [],
      },
    };
  }
  return {};
};

export default siteAdapter;
