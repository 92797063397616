import axios from 'axios';

import { api } from 'utils/utils-api';

import countryAdapter from './adapters/countryAdapter';

export const apiUrl = `${process.env.BILLING_API_URL}/api/v1`;
export const apiUrlV2 = `${process.env.SAS_API_URL}/api/v2`;
export const apiUrlV3 = `${process.env.SAS_API_URL}/api/v3`;

export const getCountries = (parameters = {}) => {
  return axios
    .get(`${apiUrl}/countries${parameters.withInternational ? '?withInternational=1' : ''}`, {
      mode: 'cors',
      headers: {
        Accept: 'application/vnd.api+json',
      },
    })
    .then(response => {
      return {
        data: countryAdapter(response.data.data),
      };
    })
    .catch(error => Promise.reject(error));
};

export const getBuycardCountries = sasAccessToken =>
  api
    .get(`${apiUrlV2}/buycards/countries`, {
      extendedHeaders: { Authorization: `Bearer ${sasAccessToken}` },
    })
    .then(response => response.data.data)
    .catch(error => Promise.reject(error));

export const getBuycard = id =>
  api
    .get(`${apiUrlV3}/countries/${id}/buycards`)
    .then(response => {
      return {
        data: response.data?.data?.attributes?.buycard_units,
        meta: response.data?.meta,
      };
    })
    .catch(error => Promise.reject(error));
