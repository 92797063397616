import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import Icon from 'components/atoms/Icon';
import Typography from 'components/atoms/Typography';

import styles from './InfoBox.scss';

const cx = classNames.bind(styles);

const InfoBox = ({ variant, title, titleAs, message, icon, className, cloudy }) => (
  <div
    className={cx('infobox', {
      [`infobox--${variant}`]: true,
      [className]: true,
      'infobox--cloudy': cloudy,
    })}
  >
    <Typography as={titleAs} className={cx('infobox_header')}>
      <Icon className={cx('infobox_header_icon')} name={icon} />
      {title && <span className={cx('infobox_header_title')}>{title}</span>}
    </Typography>
    <div className={cx('infobox_message')}>{message}</div>
  </div>
);

InfoBox.propTypes = {
  message: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.node,
    PropTypes.elementType,
  ]).isRequired,
  title: PropTypes.string,
  titleAs: PropTypes.oneOf(['body-large', 'h1', 'h2', 'body', 'inline']),
  variant: PropTypes.oneOf(['secondary', 'info', 'default', 'warning', 'success', 'danger']),
  icon: PropTypes.string,
  className: PropTypes.string,
  cloudy: PropTypes.bool,
};

InfoBox.defaultProps = {
  title: '',
  titleAs: 'body-large',
  variant: 'info',
  icon: 'info',
  className: '',
  cloudy: false,
};

export default InfoBox;
