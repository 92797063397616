import { createAsyncThunk } from '@reduxjs/toolkit';

import { withToastForError } from 'redux/sliceTools';

import { getZoneBuycard, putBuycard } from 'services/buycard-service';

export const fetchBuycard = createAsyncThunk(
  'buycard/fetchBuycard',
  withToastForError(async ({ zoneId }) => {
    const response = await getZoneBuycard(zoneId);
    return response;
  })
);

export const updateBuycard = createAsyncThunk(
  'buycard/updateBuycard',
  withToastForError(async ({ data }) => {
    const response = await putBuycard(data);
    return response;
  })
);
