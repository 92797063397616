import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { createLogger } from 'redux-logger';

import publisherRootReducer from './publisherRootReducer';

const loggerMiddleware = createLogger();

let middlewares = [...getDefaultMiddleware()];

if (process.env.NODE_ENV === 'development') {
  middlewares = [...middlewares, loggerMiddleware];
}

const publisherStore = configureStore({
  reducer: publisherRootReducer,
  middleware: middlewares,
  devTools: process.env.NODE_ENV !== 'production',
});

export const setupStore = preloadedState => {
  return configureStore({
    reducer: publisherRootReducer,
    preloadedState,
  });
};

export default publisherStore;
