/**
 * Check if the a duration has expired
 * @param {Date} creationDate The date when the token has been created
 * @param {int} duration The TTL of the token
 * @returns {boolean}
 */
export const hasExpired = (creationDate, duration) => {
  if (new Date().getTime() - creationDate > duration) {
    return true;
  }
  return false;
};

/**
 * Check publisher token has expired
 * @return {boolean}
 */
export const publisherTokenHasExpired = () => {
  return hasExpired(
    localStorage.getItem('publisher_access_token_created_at'),
    localStorage.getItem('publisher_access_token_expires_in')
  );
};

/**
 * Stores the url from which
 * @returns {void}
 */
export const storeRedirectUri = pathname => {
  if (pathname !== '/') {
    localStorage.setItem('redirect_uri', `${pathname}`);
  }
};

/**
 * Get expires
 * @param {string} token
 * @return {string}
 */
export const getTokenExpiresFromToken = token => {
  if (!token) {
    return '';
  }

  const splittedToken = token.split('.');
  if (splittedToken.length < 2) {
    return '';
  }
  return JSON.parse(atob(splittedToken[1])).exp;
};

/**
 * Check token has expired
 * @return {boolean}
 */
export const tokenHasExpired = givenToken => {
  const token =
    givenToken !== undefined && givenToken ? givenToken : localStorage.getItem('access_token');
  if (!token) {
    return true;
  }

  const expires = getTokenExpiresFromToken(token);
  if (!expires) {
    return true;
  }

  const date = new Date(Date.now());
  date.setDate(date.getDate() + 2);
  const expireDate = new Date(expires * 1000);
  if (expireDate.getTime() > date.getTime()) {
    return true;
  }

  return Date.now() > expires * 1000;
};

/**
 * Get token form localStorage
 * @returns {string}
 */
export const getTokenFromStorage = keyName => {
  if (
    !localStorage.getItem(keyName) ||
    localStorage.getItem(keyName) === '' ||
    (keyName === 'access_token' && tokenHasExpired())
  ) {
    return '';
  }

  return localStorage.getItem(keyName);
};

/**
 * Get redirect uri from local storage
 * @return {string}
 */
export const getRedirectUri = () => localStorage.getItem('redirect_uri');

/**
 * Chek if user is authenticated
 * @return {boolean}
 */
export const isAuthenticated = () => getTokenFromStorage('access_token') !== '';

/**
 * Parse the token to get user id of the authentificated user
 * @param {string} token
 * @returns {int}
 */
export const getAuthUserId = token => {
  const accessToken = token || getTokenFromStorage('access_token');
  return accessToken ? JSON.parse(atob(accessToken.split('.')[1])).sub : '';
};

/**
 * Parse the hash of url to get the token
 * @param {string} urlHash the hash (containing #) of the url
 * @returns {string}
 */
export const getTokenfromUrl = () => {
  const urlHash = window.location.hash;
  return urlHash.match(/#(?:access_token)=([\S\s]*?)&/)
    ? urlHash.match(/#(?:access_token)=([\S\s]*?)&/)[1]
    : '';
};

/**
 * Get token expires at
 * @return {string}
 */
export const getTokenExpiresAt = token => {
  const accessToken = token || getTokenFromStorage('access_token');
  return getTokenExpiresFromToken(accessToken);
};

/**
 * Store the token in localStorage
 * @param {string} token
 * @returns {void}
 */
export const storeAccessToken = token => {
  localStorage.setItem('access_token', token);
};

/**
 * Remove publiserh token and associated keys from local storage
 * @return {void}
 */
export const removePublisherAccessToken = async (withInfo = true) => {
  await localStorage.removeItem('publisher_access_token');
  await localStorage.removeItem('publisher_access_token_created_at');
  await localStorage.removeItem('publisher_access_token_expires_in');
  if (withInfo) {
    await localStorage.removeItem('publisher_info');
  }
};

/**
 * Remove token and associated keys from local storage
 * @return {void}
 */
export const removeAccessToken = async () => {
  await removePublisherAccessToken(true);
  await localStorage.removeItem('access_token');
  await localStorage.removeItem('token_created_at');
  await localStorage.removeItem('token_expires_in');
};

/**
 * Remove redirect uri from local storage
 * @return {void}
 */
export const removeRedirectUri = () => {
  localStorage.removeItem('redirect_uri');
};

/**
 * Authenticate user
 * @param  {string} token
 * @return {void}
 */
export const authenticate = token => {
  const expires = tokenHasExpired(token);
  if (expires) {
    return false;
  }
  storeAccessToken(token);
  return true;
};

/**
 * Get information from current window.location
 * @return {object}
 */
export const getLocationInfo = async () => {
  const { pathname, search, origin } = window.location;
  const currentOrigin = await origin.replace('http://', 'https://');
  const isExternalBo = await (currentOrigin === process.env.BO_EXTERNAL_URL);
  const clientId = await (isExternalBo ? process.env.EXTERNAL_CLIENT_ID : process.env.CLIENT_ID);
  const callbackUrl = await (isExternalBo ? `${currentOrigin}/publishers/` : currentOrigin);

  return {
    pathname,
    search,
    origin,
    isExternalBo,
    clientId,
    currentOrigin,
    callbackUrl,
  };
};

/**
 * Redirect to login page (UMS for now)
 * @return {void}
 */
export const redirectToLogin = async (storingCurrentUrl = true) => {
  const { pathname, search, currentOrigin, isExternalBo } = await getLocationInfo();

  if (
    pathname !== '/' &&
    storingCurrentUrl &&
    !['/not-authorized', '/publishers', '/login', '/forgot-password', '/reset-password'].some(v =>
      pathname.includes(v)
    )
  ) {
    await storeRedirectUri(`${pathname}${search || ''}`);
  }

  if (isExternalBo && search) {
    const queryParams = new URLSearchParams(search);
    const fromRedirection = queryParams.get('popup');
    if (fromRedirection === 'new_interface') {
      await localStorage.setItem('show_popup_new_interface', 1);
    }
  }
  window.location.assign(`${currentOrigin}/login`);
};

/**
 * Get homepage of logged user
 * @return {string}
 */
export const getLoggedHomePage = async () => {
  const { callbackUrl } = await getLocationInfo();
  return callbackUrl;
};

/**
 * Redirect after generated access token from API
 * @param  {string} token
 * @return {void}
 */
export const redirectAfterLogin = async token => {
  const callbackUrl = await getLoggedHomePage();
  return window.location.assign(`${callbackUrl}#access_token=${token}&`);
};

/**
 * Build additional params allowing to authenticate user from url
 * @return {string}
 */
export const generateAuthParams = usePublisherIfExists => {
  let token = '';
  if (usePublisherIfExists !== 'undefined' && !!usePublisherIfExists) {
    token = getTokenFromStorage('publisher_access_token');
    if (token) {
      return `#access_token=${token}&token_type=Bearer`;
    }
  }

  token = getTokenFromStorage('access_token');
  const expires = getTokenExpiresAt(token);
  if (!token || !expires) {
    return '';
  }
  return `#access_token=${token}&token_type=Bearer&expires_in=${expires}`;
};

/**
 * Build auth header for api request
 * @return {object}
 */
export const getAuthHeaders = async usePublisherIfExists => {
  const asPublisher = usePublisherIfExists !== 'undefined' && !!usePublisherIfExists;
  const localStorageKey = asPublisher ? 'publisher_access_token' : 'access_token';
  let accessToken = await getTokenFromStorage(localStorageKey);

  if (asPublisher && accessToken && publisherTokenHasExpired()) {
    await removePublisherAccessToken(false);
    return window.location.reload();
  }

  if (!accessToken && asPublisher) {
    accessToken = await getTokenFromStorage('access_token');
  }

  if (!accessToken) {
    return redirectToLogin(true);
  }

  return {
    Accept: 'application/vnd.api+json',
    Authorization: `Bearer ${accessToken}`,
  };
};
