import { createSelector, createSlice } from '@reduxjs/toolkit';

import countriesAdapter from './countriesAdapter';
import { fetchCountries, fetchCountryBuycard } from './countriesAsyncThunk';

const initialState = countriesAdapter.getInitialState({
  currentRequestId: undefined,
  status: 'idle',
  current: {
    data: {},
    status: 'idle',
    statusBuycard: 'idle',
  },
});

/* eslint-disable no-param-reassign */
const countriesSlice = createSlice({
  name: 'countries',
  initialState,
  reducers: {
    clearCountry(state) {
      state.current = {
        statusBuycard: 'idle',
        status: 'idle',
        data: {},
      };
    },
  },
  extraReducers: builder => {
    builder
      // Get multi
      .addCase(fetchCountries.pending, (state, action) => {
        state.status = 'loading';
        state.currentRequestId = action.meta.requestId;
      })
      .addCase(fetchCountries.fulfilled, (state, action) => {
        const { data } = action.payload;
        const { requestId } = action.meta;
        if (state.status === 'loading' && requestId === state.currentRequestId) {
          countriesAdapter.setAll(state, data);
          state.status = 'loaded';
          state.currentRequestId = undefined;
        }
      })
      .addCase(fetchCountries.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (state.status === 'loading' && state.currentRequestId === requestId) {
          state.status = 'idle';
          state.currentRequestId = undefined;
        }
      })

      // get buycard
      .addCase(fetchCountryBuycard.pending, state => {
        state.current.statusBuycard = 'loading';
      })
      .addCase(fetchCountryBuycard.fulfilled, (state, action) => {
        const { data, meta } = action.payload;
        const { hierarchy, ...rest } = meta;
        state.current.data = {
          ...state.current.data,
          buycard: {
            country: data,
            hierarchy: hierarchy || [],
            meta: rest || {},
          },
        };
        state.current.statusBuycard = 'loaded';
      })
      .addCase(fetchCountryBuycard.rejected, state => {
        state.current.statusBuycard = 'error';
      });
  },
});
/* eslint-enable no-param-reassign */

// Simple actions
export const { clearCountry } = countriesSlice.actions;

// Selectors
export const { selectAll: selectCountries, selectById: selectCountryById } =
  countriesAdapter.getSelectors(state => state.countries);

export const selectCountryIds = createSelector(selectCountries, countries =>
  countries.map(country => country.id)
);

export const selectCountryAttributes = createSelector(selectCountries, countries =>
  countries.map(country => country.attributes)
);

export const selectCountriesFetchStatus = state => state.countries.status;
export const selectCurrentCountry = state => state.countries.current;
export const selectCurrentCountryFetchStatus = state => state.countries.current.status;
export const selectCurrentCountryBuycardStatus = state => state.countries.current.statusBuycard;
export const selectCountryBuycardUnitsMeta = state => state.countries.current.data.buycard?.meta;
export const selectCountryBuycardUnits = createSelector(selectCurrentCountry, current => {
  const units = {};
  if (current?.data?.buycard?.country?.length) {
    current.data.buycard.country.map(u => {
      if (['skinroll', 'inkroll'].includes(u.placement_type)) {
        return { ...u };
      }
      if (!(u.placement_type in units)) {
        units[u.placement_type] = [];
      }
      units[u.placement_type].push(u);
      return { ...u };
    });
  }
  return units;
});

const countriesReducer = countriesSlice.reducer;
export default countriesReducer;
