import { createAsyncThunk } from '@reduxjs/toolkit';

import { withToastForError } from 'redux/sliceTools';

import {
  getLead,
  getLeadByField,
  getLeads,
  postLead,
  postUnregisteredLead,
  putLead,
} from 'services/lead-service';

export const fetchLeads = createAsyncThunk(
  'leads/fetchLeads',
  withToastForError(async ({ page, search, filters, sort }) => {
    const response = await getLeads(page, search, filters, sort);
    return response;
  })
);

export const fetchLeadByField = createAsyncThunk(
  'leads/fetchLeadByField',
  withToastForError(async ({ field, value, token }) => {
    const response = await getLeadByField(field, value, token);
    return response;
  })
);

export const fetchLead = createAsyncThunk(
  'leads/fetchLead',
  withToastForError(async ({ id }) => {
    const response = await getLead(id);
    return response;
  })
);

export const updateLead = createAsyncThunk(
  'leads/updateLead',
  withToastForError(async ({ id, data }) => {
    const response = await putLead(id, data);
    return response;
  })
);

export const createLead = createAsyncThunk(
  'leads/createLead',
  withToastForError(async ({ data }) => {
    const response = await postLead(data);
    return response;
  })
);

export const createUnregisteredLead = createAsyncThunk(
  'leads/createUnregisteredLead',
  withToastForError(async ({ data }) => {
    const response = await postUnregisteredLead(data);
    return response;
  })
);
