import { api } from 'utils/utils-api';

export const apiUrlV3 = `${process.env.SAS_API_URL}/api/v3`;

export const getZoneBuycard = zoneId =>
  api
    .get(`${apiUrlV3}/buycards?zones=${zoneId}`)
    .then(response => {
      return response.data;
    })
    .catch(error => Promise.reject(error));

export const putBuycard = data =>
  api
    .put(`${apiUrlV3}/buycards`, data)
    .then(response => {
      return {
        meta: response.data?.meta,
      };
    })
    .catch(error => Promise.reject(error));
