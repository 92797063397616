import PropTypes from 'prop-types';
import React, { Suspense } from 'react';

const Lazyload = ({ children, loader }) => <Suspense fallback={loader}>{children}</Suspense>;

Lazyload.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.elementType, PropTypes.node])
    .isRequired,
  loader: PropTypes.oneOfType([PropTypes.element, PropTypes.elementType, PropTypes.node])
    .isRequired,
};

export default Lazyload;
