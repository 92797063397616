import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './Typography.scss';

const cx = classNames.bind(styles);

const asMapping = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h3bold: 'h3',
  h4: 'h4',
  h5: 'h5',
  title: 'h5',
  'small-title': 'h5',
  'card-title': 'h5',
  'modal-title': 'h5',
  'modal-title-fit-content': 'h5',
  'modal-title-small': 'h5',
  'modal-title-normal': 'h5',
  'modal-title-large': 'h5',
  'modal-title-xlarge': 'h5',
  h6: 'h6',
  subtitle: 'h6',
  body: 'p',
  'body-semibold': 'p',
  'body-large': 'p',
  'body-small': 'p',
  'body-xsmall': 'p',
  bodythin: 'p',
  inline: 'span',
  inlineInherit: 'span',
  label: 'span',
  tab: 'span',
  pagetitle: 'span',
};

const Typography = ({
  as,
  fontFamily,
  className,
  title,
  variant,
  noMargin,
  forceBold,
  disabled,
  italic,
  centered,
  underlined,
  children,
}) => {
  const Component = asMapping[as];

  return (
    <Component
      className={cx(className, {
        typography: true,
        [`typography--${as}`]: true,
        [`typography--${variant}`]: variant !== null,
        [`typography--${fontFamily}`]: fontFamily !== null,
        'typography--no-margin': noMargin,
        'typography--force-bold': forceBold,
        'typography--centered': centered,
        'typography--underlined': underlined,
        'typography--disabled': disabled,
        'typography--italic': italic,
      })}
      title={title}
    >
      {children}
    </Component>
  );
};

Typography.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.node, PropTypes.elementType])
    .isRequired,
  as: PropTypes.oneOf(Object.keys(asMapping)),
  fontFamily: PropTypes.string,
  className: PropTypes.string,
  title: PropTypes.string,
  variant: PropTypes.string,
  noMargin: PropTypes.bool,
  forceBold: PropTypes.bool,
  centered: PropTypes.bool,
  underlined: PropTypes.bool,
  disabled: PropTypes.bool,
  italic: PropTypes.bool,
};

Typography.defaultProps = {
  as: 'body',
  fontFamily: 'openSans',
  className: '',
  title: null,
  variant: null,
  noMargin: false,
  forceBold: false,
  disabled: false,
  italic: false,
  centered: false,
  underlined: false,
};

export default Typography;
