import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './Loader.scss';

const cx = classNames.bind(styles);

const Loader = ({ roller, color, centered, className }) => {
  const renderRound = (nb, type) => {
    const Component = type;
    let renderHtml = [];
    for (let i = nb - 1; i >= 0; i -= 1) {
      renderHtml = [
        ...renderHtml,
        <Component key={i} className={cx('loader_dot', `loader_dot--${color}`)} />,
      ];
    }
    return renderHtml;
  };

  const nb = roller ? 8 : 3;
  const type = roller ? 'div' : 'i';

  return (
    <div
      className={cx('loader', {
        'loader--roller': roller,
        'loader--dots': !roller,
        'loader--centered': centered,
        [className]: true,
      })}
    >
      {renderRound(nb, type)}
    </div>
  );
};

Loader.propTypes = {
  color: PropTypes.oneOf(['primary', 'secondary', 'white', 'default']),
  roller: PropTypes.bool,
  centered: PropTypes.bool,
  className: PropTypes.string,
};

Loader.defaultProps = {
  color: 'primary',
  roller: true,
  centered: true,
  className: '',
};

export default Loader;
