import { showError } from 'redux/features/alert/alertSlice';

// tools for slice
export const withToastForError = payloadCreator => {
  return async (args, thunkAPI) => {
    try {
      return await payloadCreator(args);
    } catch (e) {
      const { dispatch, rejectWithValue } = thunkAPI;
      const error = rejectWithValue(e);
      if (error.payload.response?.status === 401 || error.payload.response === 403) {
        dispatch(showError({ message: 'Unauthorized action!' }));
      } else if (error.payload.response) {
        const message = error.payload.response.errors
          ? error.payload.response.errors.message
          : error.payload.response.data.message
          ? error.payload.response.data.message
          : error.payload.response.data.errors[0];
        if (!args?.withoutAlert) {
          dispatch(showError({ message }));
        }
        throw new Error(message);
      }
      throw e;
    }
  };
};
