import axios from 'axios';

import { getAuthHeaders } from 'utils/authHelpers';

export const apiCall = async (method, url, data = {}, args = {}) => {
  const overridedArgs = {
    usePublisherTokenIfExists: false,
    extendedHeaders: {},
    extendedOptions: {},
    overridedOptions: null,
    ...args,
  };

  const { usePublisherTokenIfExists, extendedHeaders, extendedOptions, overridedOptions } =
    overridedArgs;

  let authHeaders;
  if (overridedOptions === null) {
    authHeaders = await getAuthHeaders(usePublisherTokenIfExists);
  }

  const options =
    overridedOptions !== null
      ? overridedOptions
      : {
          mode: 'cors',
          headers: { ...authHeaders, ...extendedHeaders },
          ...extendedOptions,
        };

  if (['post', 'put', 'patch'].includes(method)) {
    return axios[method](url, data, options);
  }

  return axios[method](url, options);
};

export const api = {
  post: (url, data = {}, args = {}) => apiCall('post', url, data, args),
  put: (url, data = {}, args = {}) => apiCall('put', url, data, args),
  patch: (url, data = {}, args = {}) => apiCall('patch', url, data, args),
  get: (url, args = {}) => apiCall('get', url, {}, args),
  delete: (url, args = {}) => apiCall('delete', url, {}, args),
};
