const zoneAdapter = z => {
  if (z.id && z.attributes) {
    return {
      id: z.id,
      ...z.attributes,
      cmp_wait_timeout: parseInt(z.attributes.cmp_wait_timeout, 10),
      cmp_consent_timeout: parseInt(z.attributes.cmp_consent_timeout, 10),
      mobile_setup: {
        ...(z.attributes.mobile_setup ? z.attributes.mobile_setup : {}),
        footer_fixed: z.attributes.mobile_setup?.footer_fixed
          ? parseInt(z.attributes.mobile_setup.footer_fixed, 10)
          : 0,
      },
      relationships: {
        ...z.relationships,
      },
    };
  }
  return {};
};

export default zoneAdapter;
