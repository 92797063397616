import { createAsyncThunk } from '@reduxjs/toolkit';

import { withToastForError } from 'redux/sliceTools';

import {
  getBuycard,
  getZone,
  getZoneHistory,
  getZonePrebidConfig,
  getZones,
  postZone,
  postZoneCopy,
  postZonePassbackWithCsv,
  postZonePrebidConfig,
  postZoneUpdateWithCsv,
  putSitelistSsp,
  putZone,
  putZoneMonetization,
  putZonePrebidConfig,
} from 'services/zone-service';

export const fetchZones = createAsyncThunk(
  'zones/fetchZones',
  withToastForError(async ({ page, search, filters }) => {
    const response = await getZones(page, search, filters);
    return response;
  })
);

export const globalSearchZones = createAsyncThunk(
  'zones/globalSearchZones',
  withToastForError(async ({ page, search }) => {
    const response = await getZones(page, search);
    return response;
  })
);

export const fetchZone = createAsyncThunk(
  'zones/fetchZone',
  withToastForError(async ({ id }) => {
    const response = await getZone(id);
    return response;
  })
);

export const fetchZoneHistory = createAsyncThunk(
  'zones/fetchZoneHistory',
  withToastForError(async ({ id }) => {
    const response = await getZoneHistory(id);
    return response;
  })
);

export const fetchZonePrebidConfig = createAsyncThunk(
  'zones/fetchZonePrebidConfig',
  withToastForError(async ({ sasAccessToken, id }) => {
    const response = await getZonePrebidConfig(sasAccessToken, id);
    return response;
  })
);

export const fetchZoneBuycard = createAsyncThunk(
  'zones/fetchZoneBuycard',
  withToastForError(async ({ id }) => {
    const response = await getBuycard(id);
    return response;
  })
);

export const createZone = createAsyncThunk(
  'zones/createZone',
  withToastForError(async ({ data }) => {
    const response = await postZone(data);
    return response;
  })
);

export const createZonePrebidConfig = createAsyncThunk(
  'zones/createZonePrebidConfig',
  withToastForError(async ({ id, data }) => {
    const response = await postZonePrebidConfig(id, data);
    return response;
  })
);

export const updateZone = createAsyncThunk(
  'zones/updateZone',
  withToastForError(async ({ id, data }) => {
    const response = await putZone(id, data);
    return response;
  })
);

export const updateZoneMonetization = createAsyncThunk(
  'zones/updateZoneMonetization',
  withToastForError(async ({ sasAccessToken, data }) => {
    const response = await putZoneMonetization(sasAccessToken, data);
    return response;
  })
);

export const updateZonePrebidConfig = createAsyncThunk(
  'zones/updateZonePrebidConfig',
  withToastForError(async ({ id, prebidConfigId, data }) => {
    const response = await putZonePrebidConfig(id, prebidConfigId, data);
    return response;
  })
);

export const updateSitelistSsp = createAsyncThunk(
  'zones/updateSitelistSsp',
  withToastForError(async ({ sasAccessToken, data }) => {
    const response = await putSitelistSsp(sasAccessToken, data);
    return response;
  })
);

export const duplicateZone = createAsyncThunk(
  'zones/duplicateZone',
  withToastForError(async ({ id, siteId }) => {
    const response = await postZoneCopy(id, siteId);
    return response;
  })
);

export const updateZonePassbacksFromCsv = createAsyncThunk(
  'zones/updateZonePassbacksFromCsv',
  withToastForError(async ({ data }) => {
    const response = await postZonePassbackWithCsv(data);
    return response;
  })
);

export const updateZonesFromCsv = createAsyncThunk(
  'zones/updateZonesFromCsv',
  withToastForError(async ({ data }) => {
    const response = await postZoneUpdateWithCsv(data);
    return response;
  })
);
