import { createSelector, createSlice } from '@reduxjs/toolkit';

import { updateBuycard } from '../buycards/buycardsAsyncThunk';
import zonesAdapter from './zonesAdapter';
import {
  createZone,
  createZonePrebidConfig,
  duplicateZone,
  fetchZone,
  fetchZoneBuycard,
  fetchZonePrebidConfig,
  fetchZones,
  globalSearchZones,
  updateZone,
  updateZoneMonetization,
  updateZonePassbacksFromCsv,
  updateZonePrebidConfig,
  updateZonesFromCsv,
} from './zonesAsyncThunk';

const initialState = zonesAdapter.getInitialState({
  currentRequestId: undefined,
  status: 'idle',
  errors: {},
  current: {
    currentRequestId: undefined,
    status: 'idle',
    statusPrebidConfig: 'idle',
    statusBuycard: 'idle',
    data: {},
  },
  sample: {
    currentRequestId: undefined,
    status: 'idle',
    data: [],
  },
  import: {
    status: 'idle',
    data: [],
  },
  meta: {},
  links: {},
});

/* eslint-disable no-param-reassign */
const zonesSlice = createSlice({
  name: 'zones',
  initialState,
  reducers: {
    setZone(state, action) {
      const zone = action.payload;
      state.current = {
        status: 'loaded',
        data: zone,
      };
    },
    updateZoneIntegrationStatus(state, action) {
      const { status, value } = action.payload;
      state.current = {
        status: 'loaded',
        data: {
          ...state.current.data,
          relationships: {
            ...state.current.data.relationships,
            setup_zone: {
              ...state.current.data.relationships.setup_zone,
              attributes: {
                ...state.current.data.relationships.setup_zone.attributes,
                [status]: value,
              },
            },
          },
        },
      };
    },
    clearZone(state) {
      state.current = {
        currentRequestId: undefined,
        status: 'idle',
        statusPrebidConfig: 'idle',
        statusBuycard: 'idle',
        data: {},
      };
    },
    resetZoneBuycardStatus(state) {
      state.current.statusBuycard = 'idle';
    },
    clearZones(state) {
      zonesAdapter.removeAll(state);
    },
    clearZonesSample(state) {
      state.sample = {
        status: 'idle',
        data: {},
      };
    },
  },
  extraReducers: builder => {
    builder
      // Get multi
      .addCase(fetchZones.pending, (state, action) => {
        state.status = 'loading';
        state.currentRequestId = action.meta.requestId;
      })
      .addCase(fetchZones.fulfilled, (state, action) => {
        const { data, links, meta } = action.payload;
        const { requestId } = action.meta;
        if (state.status === 'loading' && requestId === state.currentRequestId) {
          zonesAdapter.setAll(state, data);
          state.status = 'loaded';
          state.meta = meta || {};
          state.links = links || {};
          state.currentRequestId = undefined;
        }
      })
      .addCase(fetchZones.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (state.status === 'loading' && state.currentRequestId === requestId) {
          state.status = 'idle';
          state.currentRequestId = undefined;
        }
      })

      // Get sample
      .addCase(globalSearchZones.pending, (state, action) => {
        state.sample.status = 'loading';
        state.sample.currentRequestId = action.meta.requestId;
        state.sample.data = [];
      })
      .addCase(globalSearchZones.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (state.sample.status === 'loading' && requestId === state.sample.currentRequestId) {
          const { data } = action.payload;
          state.sample.status = 'loaded';
          state.sample.data = data;
        }
      })
      .addCase(globalSearchZones.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (state.sample.status === 'loading' && state.sample.currentRequestId === requestId) {
          state.sample.status = 'idle';
          state.sample.currentRequestId = undefined;
        }
      })

      // get single
      .addCase(fetchZone.pending, (state, action) => {
        const { arg } = action.meta;
        if (!arg.noLoading) {
          state.current.status = 'loading';
        }
      })
      .addCase(fetchZone.fulfilled, (state, action) => {
        const updatedData =
          state.current.data.id === action.payload.id
            ? {
                ...state.current.data,
                ...action.payload,
              }
            : action.payload;

        const update = {
          id: action.payload.id,
          changes: updatedData,
        };
        zonesAdapter.updateOne(state, update);
        state.current.data = updatedData;
        state.current.status = 'loaded';
      })
      .addCase(fetchZone.rejected, state => {
        state.current.status = 'not-found';
      })

      // get prebid conf
      .addCase(fetchZonePrebidConfig.pending, state => {
        state.current.statusPrebidConfig = 'loading';
      })
      .addCase(fetchZonePrebidConfig.fulfilled, (state, action) => {
        const { config } = action.payload;
        state.current.data = {
          ...state.current.data,
          prebidConfig: config,
        };
        state.current.statusPrebidConfig = 'loaded';
      })
      .addCase(fetchZonePrebidConfig.rejected, state => {
        state.current.statusPrebidConfig = 'error';
      })

      // get buycard
      .addCase(fetchZoneBuycard.pending, state => {
        state.current.statusBuycard = 'loading';
      })
      .addCase(fetchZoneBuycard.fulfilled, (state, action) => {
        const { data, meta } = action.payload;
        const { hierarchy, zones } = meta;
        state.current.data = {
          ...state.current.data,
          id: parseInt(zones, 10),
          buycard: {
            zone: data,
            hierarchy: hierarchy || [],
          },
        };
        state.current.statusBuycard = 'loaded';
      })
      .addCase(fetchZoneBuycard.rejected, state => {
        state.current.statusBuycard = 'error';
      })

      // update buycard
      .addCase(updateBuycard.pending, (state, action) => {
        if (action.meta?.arg?.data?.entity_type === 'zone') {
          state.current.currentRequestId = action.meta.requestId;
          state.current.statusBuycard = 'updating';
        }
      })
      .addCase(updateBuycard.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (state.current.currentRequestId === requestId) {
          state.current.statusBuycard = 'updated';
          state.current.currentRequestId = undefined;
        }
      })
      .addCase(updateBuycard.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (state.current.currentRequestId === requestId) {
          state.current.statusBuycard = 'error';
          state.current.currentRequestId = undefined;
        }
      })

      // update single
      .addCase(updateZone.pending, state => {
        state.current.status = 'updating';
      })
      .addCase(updateZone.fulfilled, (state, action) => {
        const update = {
          id: action.payload.id,
          changes: action.payload,
        };
        zonesAdapter.updateOne(state, update);
        state.current.data = action.payload;
        state.current.status = 'updated';
      })
      .addCase(updateZone.rejected, state => {
        state.current.status = 'idle';
      })

      // update prebid conf
      .addCase(updateZonePrebidConfig.pending, state => {
        state.current.statusPrebidConfig = 'updating';
      })
      .addCase(updateZonePrebidConfig.fulfilled, (state, action) => {
        const { config } = action.payload;
        state.current.data = {
          ...state.current.data,
          prebidConfig: config,
        };
        state.current.statusPrebidConfig = 'updated';
      })
      .addCase(updateZonePrebidConfig.rejected, state => {
        state.current.statusPrebidConfig = 'error';
      })

      // create single
      .addCase(createZone.pending, state => {
        state.current.status = 'creating';
      })
      .addCase(createZone.fulfilled, (state, action) => {
        state.current.data = action.payload;
        state.current.status = 'created';
      })
      .addCase(createZone.rejected, state => {
        state.current.status = 'idle';
      })

      // create prebid conf
      .addCase(createZonePrebidConfig.pending, state => {
        state.current.statusPrebidConfig = 'creating';
      })
      .addCase(createZonePrebidConfig.fulfilled, (state, action) => {
        const { config } = action.payload;
        state.current.data = {
          ...state.current.data,
          prebidConfig: config,
        };
        state.current.statusPrebidConfig = 'created';
      })
      .addCase(createZonePrebidConfig.rejected, state => {
        state.current.statusPrebidConfig = 'error';
      })

      // Update multi monetization
      .addCase(updateZoneMonetization.pending, state => {
        state.status = 'updating';
        state.errors = {};
      })
      .addCase(updateZoneMonetization.fulfilled, (state, action) => {
        const { not_found: notFound } = action.payload;
        if (notFound) {
          state.errors = {
            notFound,
          };
        }
        state.status = 'updated';
      })
      .addCase(updateZoneMonetization.rejected, state => {
        state.status = 'idle';
      })

      // duplicate zone
      .addCase(duplicateZone.pending, state => {
        state.current.status = 'duplicating';
      })
      .addCase(duplicateZone.fulfilled, (state, action) => {
        state.current.data = action.payload;
        state.current.status = 'duplicated';
      })
      .addCase(duplicateZone.rejected, state => {
        state.current.status = 'idle';
      })

      // Update multi passback csv
      .addCase(updateZonePassbacksFromCsv.pending, state => {
        state.import.status = 'importing';
        state.import.data = [];
      })
      .addCase(updateZonePassbacksFromCsv.fulfilled, (state, action) => {
        state.import.data = action.payload;
        state.import.status = 'imported';
      })
      .addCase(updateZonePassbacksFromCsv.rejected, state => {
        state.import.status = 'idle';
      })

      // Update multi zone csv
      .addCase(updateZonesFromCsv.pending, state => {
        state.import.status = 'importing';
        state.import.data = [];
      })
      .addCase(updateZonesFromCsv.fulfilled, (state, action) => {
        state.import.data = action.payload;
        state.import.status = 'imported';
      })
      .addCase(updateZonesFromCsv.rejected, state => {
        state.import.status = 'idle';
      });
  },
});
/* eslint-enable no-param-reassign */

// Simple actions
export const {
  setZone,
  clearZone,
  clearZones,
  updateZoneIntegrationStatus,
  clearZonesSample,
  resetZoneBuycardStatus,
} = zonesSlice.actions;

// Selectors
export const { selectAll: selectZones, selectById: selectZoneById } = zonesAdapter.getSelectors(
  state => state.zones
);

export const selectZoneIds = createSelector(selectZones, zones => zones.map(zone => zone.id));

export const selectZonesSample = state => state.zones.sample;
export const selectCurrentZone = state => state.zones.current;
export const selectZonesMeta = state => state.zones.meta;
export const selectZonesFetchStatus = state => state.zones.status;
export const selectCurrentZonePrebidConfigStatus = state => state.zones.current.statusPrebidConfig;
export const selectCurrentZoneBuycardStatus = state => state.zones.current.statusBuycard;
export const selectZonesErrors = state => state.zones.errors;
export const selectCurrentZoneStatus = state => state.zones.current.status;
export const selectZoneImport = state => state.zones.import;
export const selectZoneImportStatus = state => state.zones.import.status;

export const selectZoneBuycardUnits = createSelector(selectCurrentZone, current => {
  const units = {};
  if (current?.data?.buycard?.zone?.length) {
    current.data.buycard.zone.map(u => {
      if (!(u.placement_type in units)) {
        units[u.placement_type] = [];
      }
      units[u.placement_type].push(u);
      return { ...u };
    });
  }
  return units;
});

export const selectSiteBuycardUnits = createSelector(selectCurrentZone, current => {
  const units = {};
  if (current?.data?.buycard?.hierarchy?.length) {
    current.data.buycard.hierarchy.map(u => {
      if (['skinroll', 'inkroll'].includes(u.placement_type)) {
        return { ...u };
      }

      if (!(u.placement_type in units)) {
        units[u.placement_type] = [];
      }

      if (u.entity_type === 'site') {
        units[u.placement_type].push(u);
      }
      return { ...u };
    });
  }

  if (current?.data?.buycard?.zone?.length) {
    current.data.buycard.zone.map(u => {
      if (!(u.placement_type in units)) {
        units[u.placement_type] = [];
      }

      if (u.entity_type === 'site') {
        units[u.placement_type].push(u);
      }
      return { ...u };
    });
  }
  return units;
});

export const selectCountryBuycardUnits = createSelector(selectCurrentZone, current => {
  const units = {};
  if (current?.data?.buycard?.hierarchy?.length) {
    current.data.buycard.hierarchy.map(u => {
      if (['skinroll', 'inkroll'].includes(u.placement_type)) {
        return { ...u };
      }

      if (!(u.placement_type in units)) {
        units[u.placement_type] = [];
      }

      if (u.entity_type === 'country') {
        units[u.placement_type].push(u);
      }
      return { ...u };
    });
  }

  if (current?.data?.buycard?.zone?.length) {
    current.data.buycard.zone.map(u => {
      if (['skinroll', 'inkroll'].includes(u.placement_type)) {
        return { ...u };
      }

      if (!(u.placement_type in units)) {
        units[u.placement_type] = [];
      }

      if (u.entity_type === 'country') {
        units[u.placement_type].push(u);
      }
      return { ...u };
    });
  }
  return units;
});

const zonesReducer = zonesSlice.reducer;
export default zonesReducer;
