import { createSelector, createSlice } from '@reduxjs/toolkit';

import sellersJsonsAdapter from './sellersJsonsAdapter';
import { fetchSellersJson, updateSellersJson } from './sellersJsonsAsyncThunk';

const initialState = sellersJsonsAdapter.getInitialState({
  currentRequestId: undefined,
  status: 'idle',
  current: {
    status: 'idle',
    data: {},
  },
  meta: {},
  links: {},
});

/* eslint-disable no-param-reassign */
const sellersJsonsSlice = createSlice({
  name: 'sellersJsons',
  initialState,
  reducers: {
    setSellersJson(state, action) {
      const data = action.payload;
      state.current = {
        status: 'loaded',
        data,
      };
    },
    clearSellersJson(state) {
      state.current = {
        status: 'idle',
        data: {},
      };
    },
  },
  extraReducers: builder => {
    builder
      // get single
      .addCase(fetchSellersJson.pending, state => {
        state.current.status = 'loading';
      })
      .addCase(fetchSellersJson.fulfilled, (state, action) => {
        const update = {
          id: action.payload.id,
          changes: action.payload,
        };
        sellersJsonsAdapter.updateOne(state, update);
        state.current.data = action.payload;
        state.current.status = 'loaded';
      })
      .addCase(fetchSellersJson.rejected, state => {
        state.current.status = 'no_found';
      })

      // update single
      .addCase(updateSellersJson.pending, state => {
        state.current.status = 'updating';
      })
      .addCase(updateSellersJson.fulfilled, (state, action) => {
        const update = {
          id: action.payload.id,
          changes: action.payload,
        };
        sellersJsonsAdapter.updateOne(state, update);
        state.current.data = action.payload;
        state.current.status = 'updated';
      })
      .addCase(updateSellersJson.rejected, state => {
        state.current.status = 'error';
      });
  },
});
/* eslint-enable no-param-reassign */

// Simple actions
export const { setSellersJson, clearSellersJson } = sellersJsonsSlice.actions;

// Selectors
export const { selectAll: selectSellersJsons, selectById: selectCountryById } =
  sellersJsonsAdapter.getSelectors(state => state.sellersJsons);

export const selectCountryIds = createSelector(selectSellersJsons, sellersJsons =>
  sellersJsons.map(d => d.id)
);

export const selectSellersJsonsFetchStatus = state => state.sellersJsons.status;
export const selectSellersJsonsCurrent = state => state.sellersJsons.current;
export const selectSellersJsonsCurrentStatus = state => state.sellersJsons.current.status;

const sellersJsonsReducer = sellersJsonsSlice.reducer;
export default sellersJsonsReducer;
