import { createAsyncThunk } from '@reduxjs/toolkit';

import { withToastForError } from 'redux/sliceTools';

import {
  activateUser,
  getApiAccess,
  getPublisherUsers,
  getUser,
  getUsers,
  login,
  postApiAccess,
  postResetPassword,
  postSendResetLink,
  postUser,
  putUser,
} from 'services/user-service';

export const fetchUsers = createAsyncThunk(
  'users/fetchUsers',
  withToastForError(async ({ page, search, filters }) => {
    const response = await getUsers(page, search, filters);
    return response;
  })
);

export const fetchPublisherUsers = createAsyncThunk(
  'users/fetchPublisherUsers',
  withToastForError(async ({ id }) => {
    const response = await getPublisherUsers(id);
    return response;
  })
);

export const globalSearchUsers = createAsyncThunk(
  'users/globalSearchUsers',
  withToastForError(async ({ page, search }) => {
    const response = await getUsers(page, search);
    return response;
  })
);

export const fetchUser = createAsyncThunk(
  'users/fetchUser',
  withToastForError(async ({ id }) => {
    const response = await getUser(id);
    return response;
  })
);

export const createUser = createAsyncThunk(
  'users/createUser',
  withToastForError(async ({ data }) => {
    const response = await postUser(data);
    return response;
  })
);

export const updateUser = createAsyncThunk(
  'users/updateUser',
  withToastForError(async ({ id, data }) => {
    const response = await putUser(id, data);
    return response;
  })
);

export const updateUserActivation = createAsyncThunk(
  'users/updateUserActivation',
  withToastForError(async ({ id, activation }) => {
    const response = await activateUser(id, activation);
    return response;
  })
);

export const fetchApiAccess = createAsyncThunk(
  'users/fetchApiAccess',
  withToastForError(async ({ id }) => {
    const response = await getApiAccess(id, 2);
    return response;
  })
);

export const createApiAccess = createAsyncThunk(
  'users/createApiAccess',
  withToastForError(async ({ id, name, forceRenew }) => {
    const response = await postApiAccess(id, name, forceRenew);
    return response;
  })
);

export const loginUser = createAsyncThunk(
  'users/loginUser',
  withToastForError(async data => {
    const response = await login(data);
    return response;
  })
);

export const askResetLink = createAsyncThunk(
  'users/askResetLink',
  withToastForError(async data => {
    const response = await postSendResetLink(data);
    return response;
  })
);

export const resetPassword = createAsyncThunk(
  'users/resetPassword',
  withToastForError(async data => {
    const response = await postResetPassword(data);
    return response;
  })
);
