import { createSelector, createSlice, isAnyOf } from '@reduxjs/toolkit';

import { updateBuycard } from '../buycards/buycardsAsyncThunk';
import sitesAdapter from './sitesAdapter';
import {
  createSite,
  fetchSite,
  fetchSiteBuycard,
  fetchSites,
  globalSearchSites,
  importSites,
  updateSite,
  updateSiteBuycard,
} from './sitesAsyncThunk';

const initialState = sitesAdapter.getInitialState({
  currentRequestId: undefined,
  status: 'idle',
  current: {
    currentRequestId: undefined,
    status: 'idle',
    statusBuycard: 'idle',
    data: {},
  },
  sample: {
    currentRequestId: undefined,
    status: 'idle',
    data: [],
  },
  import: {
    status: 'idle',
    data: [],
  },
  meta: {},
  links: {},
});

/* eslint-disable no-param-reassign */
const sitesSlice = createSlice({
  name: 'sites',
  initialState,
  reducers: {
    setSite(state, action) {
      const site = action.payload;
      state.current = {
        status: 'loaded',
        data: site,
      };
    },
    clearSite(state) {
      state.current = {
        currentRequestId: undefined,
        statusBuycard: 'idle',
        status: 'idle',
        data: {},
      };
    },
    resetSiteBuycardStatus(state) {
      state.current.statusBuycard = 'idle';
    },
    clearSites(state) {
      sitesAdapter.removeAll(state);
    },
    clearSitesSample(state) {
      state.sample = {
        status: 'idle',
        data: {},
      };
    },
  },
  extraReducers: builder => {
    builder
      // Get multi
      .addCase(fetchSites.pending, (state, action) => {
        state.status = 'loading';
        state.currentRequestId = action.meta.requestId;
      })
      .addCase(fetchSites.fulfilled, (state, action) => {
        const { data, links, meta } = action.payload;
        const { requestId } = action.meta;
        if (state.status === 'loading' && requestId === state.currentRequestId) {
          sitesAdapter.setAll(state, data || []);
          state.status = 'loaded';
          state.meta = meta || {};
          state.links = links || {};
          state.currentRequestId = undefined;
        }
      })
      .addCase(fetchSites.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (state.status === 'loading' && state.currentRequestId === requestId) {
          state.status = 'idle';
          state.currentRequestId = undefined;
        }
      })

      // Get sample
      .addCase(globalSearchSites.pending, (state, action) => {
        state.sample.status = 'loading';
        state.sample.currentRequestId = action.meta.requestId;
        state.sample.data = [];
      })
      .addCase(globalSearchSites.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (state.sample.status === 'loading' && requestId === state.sample.currentRequestId) {
          const { data } = action.payload;
          state.sample.status = 'loaded';
          state.sample.data = data;
        }
      })
      .addCase(globalSearchSites.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (state.sample.status === 'loading' && state.sample.currentRequestId === requestId) {
          state.sample.status = 'idle';
          state.sample.currentRequestId = undefined;
        }
      })

      // get single
      .addCase(fetchSite.pending, state => {
        state.current.status = 'loading';
      })
      .addCase(fetchSite.fulfilled, (state, action) => {
        const updatedData =
          state.current.data.id === action.payload.id
            ? {
                ...state.current.data,
                ...action.payload,
              }
            : action.payload;

        const update = {
          id: action.payload.id,
          changes: updatedData,
        };
        sitesAdapter.updateOne(state, update);
        state.current.data = updatedData;
        state.current.status = 'loaded';
      })
      .addCase(fetchSite.rejected, state => {
        state.current.status = 'not-found';
      })

      // get buycard
      .addCase(fetchSiteBuycard.pending, state => {
        state.current.statusBuycard = 'loading';
      })
      .addCase(fetchSiteBuycard.fulfilled, (state, action) => {
        const { data, meta } = action.payload;
        const { hierarchy, site, ...rest } = meta;
        state.current.data = {
          ...state.current.data,
          id: parseInt(site, 10),
          buycard: {
            site: data,
            hierarchy: hierarchy || [],
            meta: rest || {},
          },
        };
        state.current.statusBuycard = 'loaded';
      })
      .addCase(fetchSiteBuycard.rejected, state => {
        state.current.statusBuycard = 'error';
      })

      // update single
      .addCase(updateSite.pending, state => {
        state.current.status = 'updating';
      })
      .addCase(updateSite.fulfilled, (state, action) => {
        const updatedData =
          state.current.data.id === action.payload.id
            ? {
                ...state.current.data,
                ...action.payload,
              }
            : action.payload;
        const update = {
          id: action.payload.id,
          changes: updatedData,
        };
        sitesAdapter.updateOne(state, update);
        state.current.data = updatedData;
        state.current.status = 'updated';
      })
      .addCase(updateSite.rejected, state => {
        state.current.status = 'idle';
      })

      // create single
      .addCase(createSite.pending, state => {
        state.current.status = 'creating';
      })
      .addCase(createSite.fulfilled, (state, action) => {
        state.current.data = action.payload;
        state.current.status = 'created';
      })
      .addCase(createSite.rejected, state => {
        state.current.status = 'error';
      })

      // Import sites
      .addCase(importSites.pending, state => {
        state.import.status = 'importing';
      })
      .addCase(importSites.fulfilled, (state, action) => {
        state.import.data = action.payload;
        state.import.status = 'imported';
      })
      .addCase(importSites.rejected, state => {
        state.import.status = 'idle';
      })

      // update single
      .addMatcher(isAnyOf(updateSiteBuycard.pending, updateBuycard.pending), (state, action) => {
        if (
          action.type === 'sites/updateSiteBuycard/pending' ||
          action.meta?.arg?.data?.entity_type === 'site'
        ) {
          state.current.currentRequestId = action.meta.requestId;
          state.current.statusBuycard = 'updating';
        }
      })
      .addMatcher(
        isAnyOf(updateSiteBuycard.fulfilled, updateBuycard.fulfilled),
        (state, action) => {
          const { requestId } = action.meta;
          if (state.current.currentRequestId === requestId) {
            state.current.statusBuycard = 'updated';
            state.current.currentRequestId = undefined;
          }
        }
      )
      .addMatcher(isAnyOf(updateSiteBuycard.rejected, updateBuycard.rejected), (state, action) => {
        const { requestId } = action.meta;
        if (state.current.currentRequestId === requestId) {
          state.current.statusBuycard = 'error';
          state.current.currentRequestId = undefined;
        }
      });
  },
});
/* eslint-enable no-param-reassign */

// Simple actions
export const { setSite, clearSite, clearSites, clearSitesSample, resetSiteBuycardStatus } =
  sitesSlice.actions;

// Selectors
export const { selectAll: selectSites, selectById: selectSiteById } = sitesAdapter.getSelectors(
  state => state.sites
);

export const selectSiteIds = createSelector(selectSites, sites => sites.map(site => site.id));

export const selectSitesSample = state => state.sites.sample;
export const selectCurrentSite = state => state.sites.current;
export const selectSitesMeta = state => state.sites.meta;
export const selectSitesFetchStatus = state => state.sites.status;
export const selectCurrentSiteBuycardStatus = state => state.sites.current.statusBuycard;
export const selectSiteBuycardUnitsMeta = state => state.sites.current.data.buycard?.meta;
export const selectSitePublishers = state => state.sites.current.data.relationships;
export const selectSitesImport = state => state.sites.import;

export const selectSiteBuycardUnits = createSelector(selectCurrentSite, current => {
  const units = {};
  if (current?.data?.buycard?.site?.length) {
    current.data.buycard.site.map(u => {
      if (['skinroll', 'inkroll'].includes(u.placement_type)) {
        return { ...u };
      }
      if (!(u.placement_type in units)) {
        units[u.placement_type] = [];
      }
      units[u.placement_type].push(u);
      return { ...u };
    });
  }
  return units;
});

export const selectCountryBuycardUnits = createSelector(selectCurrentSite, current => {
  const units = {};
  if (current?.data?.buycard?.hierarchy?.length) {
    current.data.buycard.hierarchy.map(u => {
      if (['skinroll', 'inkroll'].includes(u.placement_type)) {
        return { ...u };
      }
      if (!(u.placement_type in units)) {
        units[u.placement_type] = [];
      }

      if (u.entity_type === 'country') {
        units[u.placement_type].push(u);
      }
      return { ...u };
    });
  }

  if (current?.data?.buycard?.site?.length) {
    current.data.buycard.site.map(u => {
      if (['skinroll', 'inkroll'].includes(u.placement_type)) {
        return { ...u };
      }
      if (!(u.placement_type in units)) {
        units[u.placement_type] = [];
      }

      if (u.entity_type === 'country') {
        units[u.placement_type].push(u);
      }
      return { ...u };
    });
  }
  return units;
});

const sitesReducer = sitesSlice.reducer;
export default sitesReducer;
