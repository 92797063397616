import { createAsyncThunk } from '@reduxjs/toolkit';

import { withToastForError } from 'redux/sliceTools';

import { getPublisherSellersJson } from 'services/publisher-service';
import { putSellersJson } from 'services/sellersJson-service';

export const fetchSellersJson = createAsyncThunk(
  'sellersJsons/fetchSellersJson',
  withToastForError(async ({ organizationId }) => {
    const response = await getPublisherSellersJson(organizationId);
    return response;
  })
);

export const updateSellersJson = createAsyncThunk(
  'sellersJsons/updateSellersJson',
  withToastForError(async ({ id, data }) => {
    const response = await putSellersJson(id, data);
    return response;
  })
);
