import { createAsyncThunk } from '@reduxjs/toolkit';

import { withToastForError } from 'redux/sliceTools';

import { getBuycard, getCountries } from 'services/country-service';

export const fetchCountries = createAsyncThunk(
  'countries/fetchCountries',
  withToastForError(async (parameters = {}) => {
    const response = await getCountries(parameters);
    return response;
  })
);

export const fetchCountryBuycard = createAsyncThunk(
  'countries/fetchCountryBuycard',
  withToastForError(async ({ id }) => {
    const response = await getBuycard(id);
    return response;
  })
);
