const userAdapter = ({ id, attributes, relationships }) => {
  if (id && attributes) {
    return {
      id,
      externalId: attributes.external_id,
      firstname: attributes.firstname,
      lastname: attributes.lastname,
      fullName: `${attributes.firstname} ${attributes.lastname}`,
      isActive: attributes.is_active,
      email: attributes.email,
      organizationId: attributes.organization_id,
      legacyUid: attributes.legacy_user_id,
      sasAccessToken: attributes.sas_access_token,
      currentOrganizationId: relationships?.organisation?.id || null,
      relationships: {
        ...relationships,
        ...(relationships?.roles?.data
          ? {
              roles: {
                ...relationships.roles,
                slugs: relationships.roles.data.map(r => r.attributes.slug),
              },
            }
          : {}),
      },
    };
  }
  return {};
};

export default userAdapter;
