import PropTypes from 'prop-types';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { getPublisherAccess, getTos } from 'services/publisher-service';
import { checkAuthRole, checkUserEmail, getUser, logout } from 'services/user-service';

import {
  authenticate,
  generateAuthParams,
  getAuthUserId,
  getRedirectUri,
  getTokenFromStorage,
  getTokenfromUrl,
  isAuthenticated,
  redirectToLogin,
  removeAccessToken,
  removePublisherAccessToken,
  removeRedirectUri,
} from 'utils/authHelpers';

import TosInfoBox from 'components/molecules/users/TosInfoBox';

import AuthContext from './AuthContext';

const AuthProvider = ({ children, loader }) => {
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [authenticated, setAuthenticated] = useState(isAuthenticated());
  const [authUser, setAuthUser] = useState({});
  const [publisherAuthUser, setPublisherAuthUser] = useState(null);
  const [tosInformation, setTosInformation] = useState({});
  const navigate = useNavigate();

  const hasAuthUser = authenticated && authUser.id;
  const localToken = getTokenFromStorage('access_token');
  const publisherInfo = getTokenFromStorage('publisher_info');
  const publisherAccessToken = getTokenFromStorage('publisher_access_token');
  const urlToken = getTokenfromUrl();
  const accessToken = localToken || urlToken;
  const redirectUri = getRedirectUri();
  const roles = authUser?.relationships?.roles?.slugs || [];

  const getAuthParamsUrl = usePublisher => generateAuthParams(usePublisher);

  const logoutUser = async (revokeToken, storingCurrentUrl = true) => {
    setLoading(true);

    if (revokeToken && accessToken) {
      await logout(accessToken);
    }

    if (localToken || publisherInfo) {
      removeAccessToken();
    }
    redirectToLogin(storingCurrentUrl);
  };

  const connectAsPublisher = async (organizationId, reload = true) => {
    try {
      const publisher = await getPublisherAccess(organizationId);
      if (publisher.accessToken) {
        await setPublisherAuthUser(publisher);
        await localStorage.setItem('publisher_access_token_created_at', Date.now());
        await localStorage.setItem('publisher_access_token_expires_in', 3600 * 1000 * 5);
        await localStorage.setItem('publisher_access_token', publisher.accessToken);
        await localStorage.setItem('publisher_info', JSON.stringify(publisher));
      } else {
        await setPublisherAuthUser(null);
      }
    } catch (e) {
      await setPublisherAuthUser(null);
    }

    if (reload) {
      window.location.reload();
    }
  };

  const disconnectAsPublisher = async () => {
    const currentPublisherToken = localStorage.getItem('publisher_access_token');
    if (currentPublisherToken) {
      /*  eslint-disable no-empty */
      try {
        await logout(currentPublisherToken);
      } catch {}
      /*  eslint-enable no-empty */
    }
    await removePublisherAccessToken(true);
    await setPublisherAuthUser(null);

    window.location.reload();
  };

  const handleConnectAsPublisher = value => {
    return value ? connectAsPublisher(value, true) : disconnectAsPublisher();
  };

  const logoutManually = () => logoutUser(true, false);

  const getAuthUserRoles = async () => {
    try {
      const authId = getAuthUserId(accessToken);
      const user = await getUser(authId);
      setAuthUser(user);
    } catch (e) {
      if (hasAuthUser || accessToken) {
        logoutUser(false, true);
      }
    }
  };

  const updateAuthUser = data => {
    setAuthUser(data);
  };

  const loginUser = async token => {
    try {
      const isAuth = authenticate(token);
      await getAuthUserRoles();
      setAuthenticated(isAuth);
    } catch (e) {
      logoutUser(false, true);
    }
  };

  const checkRole = async role => {
    try {
      const hasRole = await checkAuthRole(role);
      return hasRole?.authorized || false;
    } catch (e) {
      return false;
    }
  };

  const checkEmail = async email => {
    try {
      const isEmailExist = await checkUserEmail(email);
      return isEmailExist.exists;
    } catch (e) {
      return true;
    }
  };

  const checkTos = async () => {
    setTosInformation({
      status: 'loading',
    });
    try {
      const tos = await getTos(authUser.organizationId, authUser.email);
      if (!tos.signed && tos.url) {
        window.location.assign(tos.url);
      } else {
        setTosInformation({
          ...tos,
          status: 'success',
        });
      }
    } catch (e) {
      setTosInformation({
        status: 'error',
      });
    }
  };

  const contextValue = useMemo(
    () => ({
      roles,
      authUser,
      accessToken,
      publisherAuthUser,
      setAuthUser,
      updateAuthUser,
      authenticated,
      setAuthenticated,
      getAuthParamsUrl,
      getAuthUserRoles,
      checkRole,
      checkEmail,
      logoutUser,
      logoutManually,
      connectAsPublisher,
      disconnectAsPublisher,
      handleConnectAsPublisher,
      TosComponent: props => <TosInfoBox status={tosInformation.status || 'loading'} {...props} />,
    }),
    [authenticated, authUser, publisherAuthUser, tosInformation]
  );

  useEffect(() => {
    if (!hasAuthUser && accessToken) {
      loginUser(accessToken);
    } else if (!authenticated || !accessToken) {
      logoutUser(true, true);
    }

    if (authenticated && redirectUri) {
      const url = redirectUri;
      removeRedirectUri();
      navigate(url, { replace: true });
    }
  }, [accessToken, authenticated, hasAuthUser, redirectUri]);

  useEffect(() => {
    if (hasAuthUser && roles?.includes('publisher') && tosInformation.status !== 'loading') {
      checkTos();
    }
  }, [authUser, location]);

  useEffect(() => {
    const currentPublisherToken = localStorage.getItem('publisher_access_token');
    if (publisherInfo && publisherInfo !== null && !currentPublisherToken) {
      const info = JSON.parse(publisherInfo);
      handleConnectAsPublisher(info.organizationId, false);
    }
  }, [publisherAccessToken]);

  useEffect(() => {
    const currentPublisherToken = localStorage.getItem('publisher_access_token');
    if (!publisherAuthUser && currentPublisherToken && publisherInfo !== null) {
      setPublisherAuthUser(JSON.parse(publisherInfo));
    }
  }, [publisherAuthUser]);

  if (loading || !hasAuthUser) {
    return loader;
  }

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
};

AuthProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.node, PropTypes.elementType])
    .isRequired,
  loader: PropTypes.oneOfType([PropTypes.element, PropTypes.node, PropTypes.elementType])
    .isRequired,
};

const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth };
