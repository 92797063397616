import { getAuthUserId, getLocationInfo } from 'utils/authHelpers';
import { api } from 'utils/utils-api';

import userAdapter from './adapters/userAdapter';

export const sasApiUrl = `${process.env.SAS_API_URL}/api/v3`;
export const apiUrl = `${process.env.UMS_API_URL}/api/v1`;
export const apiUrlV2 = `${process.env.UMS_API_URL}/api/v2`;

export const getUsers = (page, search, filters) => {
  const param = search ? `&filter[search]=${search}` : '';

  let filterParams = '';
  if (filters) {
    if (filters.status && filters.status !== 'all') {
      filterParams += `&filter[status]=${filters.status}`;
    }
    if (filters.roles) {
      filterParams += `&filter[roles]=${filters.roles}`;
    }
  }

  return api
    .get(
      `${apiUrl}/users?paginated=1&page=${page}${param}${filterParams}&relationships[]=roles&relationships[]=organisation`
    )
    .then(response => response.data)
    .catch(error => Promise.reject(error));
};

export const getPublisherUsers = id => {
  return api
    .get(`${apiUrl}/organizations/${id}/users`)
    .then(response => response.data)
    .catch(error => Promise.reject(error));
};

export const getUser = id =>
  api
    .get(`${apiUrl}/users/${id}?relationships[]=roles&relationships[]=organisation&resource=1`)
    .then(response => {
      return userAdapter(response.data.data);
    })
    .catch(error => Promise.reject(error));

export const getAuthUser = (id, accessToken) =>
  api
    .get(`${apiUrl}/users/${id}`, {
      extendedHeaders: { Authorization: `Bearer ${accessToken}` },
    })
    .then(response => {
      return userAdapter(response.data.data);
    })
    .catch(error => Promise.reject(error));

export const checkAuthRole = role =>
  api
    .get(`${apiUrl}/check-role/${role}`)
    .then(response => response.data)
    .catch(error => Promise.reject(error));

export const putUser = (id, data) =>
  api
    .put(`${apiUrl}/users/${id}?relationships[]=roles&relationships[]=organisation&resource=1`, {
      ...data,
      auth_user_id: getAuthUserId(),
    })
    .then(response => {
      return userAdapter(response.data.data);
    })
    .catch(error => Promise.reject(error));

export const checkUserEmail = email =>
  api
    .get(`${apiUrl}/users/email-exists/${email}`)
    .then(response => response.data)
    .catch(error => Promise.reject(error));

export const activateUser = (id, activation) =>
  api
    .patch(
      `${apiUrl}/users/${id}/activation?relationships[]=roles&relationships[]=organisation&resource=1`,
      { activation }
    )
    .then(response => {
      return userAdapter(response.data.data);
    })
    .catch(error => Promise.reject(error));

export const postUser = async data =>
  api
    .post(`${apiUrl}/users?relationships[]=roles&relationships[]=organisation&resource=1`, {
      ...data,
      password: btoa(Math.random().toString()).substring(8, 18),
    })
    .then(response => {
      return userAdapter(response.data.data);
    })
    .catch(error => Promise.reject(error));

export const getApiAccess = async (id, appId) => {
  return api
    .get(`${sasApiUrl}/api-access/${id}/${appId}`)
    .then(response => {
      return response.data.data;
    })
    .catch(error => Promise.reject(error));
};

export const getApiAccessTestUrl = async (id, appId) => {
  return api
    .get(`${sasApiUrl}/api-access/${id}/${appId}/test-url`)
    .then(response => {
      return response.data.data;
    })
    .catch(error => Promise.reject(error));
};

export const postApiAccess = async (id, name, forceRenew) => {
  const data = {
    user_id: id,
    api_id: 2,
    name,
    force_renew: forceRenew,
  };

  return api
    .post(`${sasApiUrl}/api-access/`, data)
    .then(response => {
      return response.data.data;
    })
    .catch(error => Promise.reject(error));
};

export const login = async data => {
  const { clientId } = await getLocationInfo();
  const formData = { ...data, client_id: clientId };
  return api
    .post(`${apiUrlV2}/login`, formData, {
      overridedOptions: {
        mode: 'cors',
        headers: {
          Accept: 'application/vnd.api+json',
        },
      },
    })
    .then(response => response.data)
    .catch(error => Promise.reject(error));
};

export const postSendResetLink = async data => {
  const { clientId, currentOrigin } = await getLocationInfo();
  const formData = { ...data, client_id: clientId, web_url: currentOrigin };

  return api
    .post(`${apiUrlV2}/reset-link`, formData, {
      overridedOptions: {
        mode: 'cors',
        headers: {
          Accept: 'application/vnd.api+json',
        },
      },
    })
    .then(response => response.data)
    .catch(error => Promise.reject(error));
};

export const postResetPassword = async data => {
  const { clientId } = await getLocationInfo();
  return api
    .post(
      `${apiUrlV2}/reset-password`,
      { ...data, client_id: clientId },
      {
        overridedOptions: {
          mode: 'cors',
          headers: {
            Accept: 'application/vnd.api+json',
          },
        },
      }
    )
    .then(response => response.data)
    .catch(error => Promise.reject(error));
};

export const logout = accessToken =>
  api
    .post(
      `${apiUrlV2}/logout`,
      {},
      {
        extendedHeaders: { Authorization: `Bearer ${accessToken}` },
      }
    )
    .then(response => response.data)
    .catch(error => Promise.reject(error));
