import { createSelector, createSlice, isAnyOf } from '@reduxjs/toolkit';
import _ from 'lodash';

import usersAdapter from './usersAdapter';
import {
  createApiAccess,
  createUser,
  fetchApiAccess,
  fetchPublisherUsers,
  fetchUser,
  fetchUsers,
  globalSearchUsers,
  updateUser,
  updateUserActivation,
} from './usersAsyncThunk';

const initialState = usersAdapter.getInitialState({
  currentRequestId: undefined,
  status: 'idle',
  current: {
    status: 'idle',
    data: {},
  },
  auth: {
    status: 'idle',
    data: {},
  },
  sample: {
    currentRequestId: undefined,
    status: 'idle',
    data: [],
  },
  meta: {},
  links: {},
});

/* eslint-disable no-param-reassign */
const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setUser(state, action) {
      const { user, auth } = action.payload;
      if (auth === true) {
        state.auth = {
          status: 'loaded',
          data: user,
        };
      } else {
        state.current = {
          status: 'loaded',
          data: user,
        };
      }
    },
    clearUser(state, action) {
      const { auth } = action.payload;
      if (auth === true) {
        state.auth = {
          status: 'idle',
          data: {},
        };
      } else {
        state.current = {
          status: 'idle',
          data: {},
        };
      }
    },
    clearUsers(state) {
      usersAdapter.removeAll(state);
      state.meta = {};
      state.link = {};
    },
    clearUsersSample(state) {
      state.sample = {
        status: 'idle',
        data: {},
      };
    },
  },
  extraReducers: builder => {
    builder
      // Get sample
      .addCase(globalSearchUsers.pending, (state, action) => {
        state.sample.status = 'loading';
        state.sample.currentRequestId = action.meta.requestId;
        state.sample.data = [];
      })
      .addCase(globalSearchUsers.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (state.sample.status === 'loading' && requestId === state.sample.currentRequestId) {
          const { data } = action.payload;
          state.sample.status = 'loaded';
          state.sample.data = data;
          state.sample.currentRequestId = undefined;
        }
      })
      .addCase(globalSearchUsers.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (state.sample.status === 'loading' && state.sample.currentRequestId === requestId) {
          state.sample.status = 'idle';
          state.sample.currentRequestId = undefined;
        }
      })

      // get single
      .addCase(fetchUser.pending, (state, action) => {
        const { arg } = action.meta;
        if (arg.auth === true) {
          state.auth.status = 'loading';
        } else {
          state.current.status = 'loading';
        }
      })
      .addCase(fetchUser.fulfilled, (state, action) => {
        const { arg } = action.meta;
        const update = {
          id: action.payload.id,
          changes: action.payload,
        };
        usersAdapter.updateOne(state, update);
        if (arg.auth === true) {
          state.auth.data = action.payload;
          state.auth.status = 'loaded';
        } else {
          state.current.data = action.payload;
          state.current.status = 'loaded';
        }
      })
      .addCase(fetchUser.rejected, (state, action) => {
        const { arg } = action.meta;
        if (arg.auth === true) {
          state.auth.status = 'error';
        } else {
          state.current.status = 'error';
        }
      })

      // create single
      .addCase(createUser.pending, state => {
        state.current.status = 'creating';
      })
      .addCase(createUser.fulfilled, (state, action) => {
        state.current.data = action.payload;
        state.current.status = 'created';
      })
      .addCase(createUser.rejected, state => {
        state.current.status = 'idle';
      })

      // fetch API access
      .addCase(fetchApiAccess.pending, state => {
        state.current.status = 'access-loading';
      })
      .addCase(fetchApiAccess.fulfilled, (state, action) => {
        state.current.data.relationships = {
          ...state.current.data.relationships,
          apiAccess: !_.isEmpty(action.payload) ? action.payload : {},
        };
        state.current.status = 'access-loaded';
      })
      .addCase(fetchApiAccess.rejected, state => {
        state.current.status = 'idle';
      })

      // generate API access
      .addCase(createApiAccess.pending, state => {
        state.current.status = 'access-creating';
      })
      .addCase(createApiAccess.fulfilled, (state, action) => {
        state.current.data.relationships = {
          ...state.current.data.relationships,
          apiAccess: action.payload,
        };
        state.current.status = 'access-created';
      })
      .addCase(createApiAccess.rejected, state => {
        state.current.status = 'idle';
      })

      // update single
      .addMatcher(isAnyOf(updateUser.pending, updateUserActivation.pending), (state, action) => {
        const { arg } = action.meta;
        if (arg.auth === true) {
          state.auth.status = 'updating';
        } else {
          state.current.status = 'updating';
        }
      })
      .addMatcher(
        isAnyOf(updateUser.fulfilled, updateUserActivation.fulfilled),
        (state, action) => {
          const { arg } = action.meta;
          const update = {
            id: action.payload.id,
            changes: action.payload,
          };
          usersAdapter.updateOne(state, update);
          if (arg.auth === true) {
            state.auth.data = action.payload;
            state.auth.status = 'updated';
          } else {
            state.current.data = action.payload;
            state.current.status = 'updated';
          }
        }
      )
      .addMatcher(isAnyOf(updateUser.rejected, updateUserActivation.rejected), (state, action) => {
        const { arg } = action.meta;
        if (arg.auth === true) {
          state.auth.status = 'idle';
        } else {
          state.current.status = 'idle';
        }
      })

      // get multi
      .addMatcher(isAnyOf(fetchUsers.pending, fetchPublisherUsers.pending), (state, action) => {
        state.status = 'loading';
        state.currentRequestId = action.meta.requestId;
      })
      .addMatcher(isAnyOf(fetchUsers.fulfilled, fetchPublisherUsers.fulfilled), (state, action) => {
        const { data, links, meta } = action.payload;
        const { requestId } = action.meta;
        if (state.status === 'loading' && requestId === state.currentRequestId) {
          usersAdapter.setAll(state, data);
          state.status = 'loaded';
          state.meta = meta || {};
          state.links = links || {};
          state.currentRequestId = undefined;
        }
      })
      .addMatcher(isAnyOf(fetchUsers.rejected, fetchPublisherUsers.rejected), (state, action) => {
        state.status = 'idle';
        const { requestId } = action.meta;
        if (state.status === 'loading' && state.currentRequestId === requestId) {
          state.status = 'idle';
          state.currentRequestId = undefined;
        }
      });
  },
});
/* eslint-enable no-param-reassign */

// Simple actions
export const { setUser, clearUser, clearUsers, clearUsersSample } = usersSlice.actions;

// Selectors
export const { selectAll: selectUsers, selectById: selectUserById } = usersAdapter.getSelectors(
  state => state.users
);

export const selectUserIds = createSelector(selectUsers, users => users.map(user => user.id));

export const selectUsersSample = state => state.users.sample;
export const selectCurrentUser = state => state.users.current;
export const selectCurrentAuthUser = state => state.users.auth;
export const selectUsersMeta = state => state.users.meta;
export const selectUsersFetchStatus = state => state.users.status;

const usersReducer = usersSlice.reducer;
export default usersReducer;
