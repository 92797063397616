import { createSelector, createSlice, isAnyOf } from '@reduxjs/toolkit';

import leadsAdapter from './leadsAdapter';
import {
  createUnregisteredLead,
  fetchLead,
  fetchLeadByField,
  fetchLeads,
  updateLead,
} from './leadsAsyncThunk';

const initialState = leadsAdapter.getInitialState({
  currentRequestId: undefined,
  status: 'idle',
  current: {
    status: 'idle',
    data: {},
  },
  meta: {},
  links: {},
});

/* eslint-disable no-param-reassign */
const leadsSlice = createSlice({
  name: 'leads',
  initialState,
  reducers: {
    setLead(state, action) {
      const lead = action.payload;
      state.current = {
        status: 'loaded',
        data: lead,
      };
    },
    clearLead(state) {
      state.current = {
        status: 'idle',
        data: {},
      };
    },
    clearLeads(state) {
      leadsAdapter.removeAll(state);
      state.meta = {};
      state.link = {};
    },
  },
  extraReducers: builder => {
    builder
      // Get multi
      .addCase(fetchLeads.pending, (state, action) => {
        state.status = 'loading';
        state.currentRequestId = action.meta.requestId;
      })
      .addCase(fetchLeads.fulfilled, (state, action) => {
        const { data, links, meta } = action.payload;
        const { requestId } = action.meta;
        if (state.status === 'loading' && requestId === state.currentRequestId) {
          leadsAdapter.setAll(state, data);
          state.status = 'loaded';
          state.meta = meta || {};
          state.links = links || {};
          state.currentRequestId = undefined;
        }
      })
      .addCase(fetchLeads.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (state.status === 'loading' && state.currentRequestId === requestId) {
          state.status = 'idle';
          state.currentRequestId = undefined;
        }
      })

      // update single
      .addCase(updateLead.pending, (state, action) => {
        const { data } = action.meta.arg;
        state.current.data = {
          ...state.current.data,
          ...data,
        };
        state.current.status = 'updating';
      })
      .addCase(updateLead.fulfilled, (state, action) => {
        const update = {
          id: action.payload.id,
          changes: action.payload,
        };
        leadsAdapter.updateOne(state, update);
        state.current.data = action.payload;
        state.current.status = 'updated';
      })
      .addCase(updateLead.rejected, state => {
        state.current.status = 'idle';
      })

      // create single unregistered
      .addCase(createUnregisteredLead.pending, (state, action) => {
        const { data } = action.meta.arg;
        state.current.data = {
          ...state.current.data,
          ...data,
        };
        state.current.status = 'creating';
      })
      .addCase(createUnregisteredLead.fulfilled, (state, action) => {
        const update = {
          id: action.payload.id,
          changes: action.payload,
        };
        leadsAdapter.updateOne(state, update);
        state.current.data = action.payload;
        state.current.status = 'created';
      })
      .addCase(createUnregisteredLead.rejected, state => {
        state.current.status = 'idle';
      })

      // get single
      .addMatcher(isAnyOf(fetchLead.pending, fetchLeadByField.pending), state => {
        state.current.status = 'loading';
      })
      .addMatcher(isAnyOf(fetchLead.fulfilled, fetchLeadByField.fulfilled), (state, action) => {
        const update = {
          id: action.payload.id,
          changes: action.payload,
        };
        leadsAdapter.updateOne(state, update);
        state.current.data = action.payload;
        state.current.status = 'loaded';
      })
      .addMatcher(isAnyOf(fetchLead.rejected, fetchLeadByField.rejected), state => {
        state.current.status = 'error';
      });
  },
});
/* eslint-enable no-param-reassign */

// Simple actions
export const { setLead, clearLead, clearLeads } = leadsSlice.actions;

// Selectors
export const { selectAll: selectLeads, selectById: selectLeadById } = leadsAdapter.getSelectors(
  state => state.leads
);

export const selectLeadIds = createSelector(selectLeads, leads => leads.map(country => country.id));

export const selectCurrentLead = state => state.leads.current;
export const selectLeadsMeta = state => state.leads.meta;
export const selectLeadsFetchStatus = state => state.leads.status;

const leadsReducer = leadsSlice.reducer;
export default leadsReducer;
