import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { AuthProvider } from 'context/AuthProvider';
import { RoleProvider } from 'context/RoleProvider';

import Lazyload from 'utils/Lazyload';

import Loader from 'components/atoms/Loader';

const NotFoundContainer = React.lazy(() => import('pages/NotFoundContainer'));
const DashboardContainer = React.lazy(() => import('pages/publishers/DashboardContainer'));
const SellerJsonFormContainer = React.lazy(() =>
  import('pages/publishers/SellerJsonFormContainer')
);
const PaymentContainer = React.lazy(() => import('pages/publishers/PaymentContainer'));
const SiteListingContainer = React.lazy(() => import('pages/publishers/SiteListingContainer'));
const SiteFormContainer = React.lazy(() => import('pages/publishers/SiteFormContainer'));
const ZoneListingContainer = React.lazy(() => import('pages/publishers/ZoneListingContainer'));
const ZoneFormContainer = React.lazy(() => import('pages/publishers/ZoneFormContainer'));
const SiteImportContainer = React.lazy(() => import('pages/publishers/SiteImportContainer'));
const ZoneImportContainer = React.lazy(() => import('pages/publishers/ZoneImportContainer'));

const PublisherAuthRoutes = () => {
  return (
    <AuthProvider loader={<Loader />}>
      <RoleProvider loader={<Loader />} roleSlugs={['publisher', 'admin']}>
        <Routes>
          <Route path="/dashboard" element={<Navigate to="/publishers/" />} />
          <Route
            path="/"
            element={
              <Lazyload loader={<Loader />}>
                <DashboardContainer />
              </Lazyload>
            }
          />
          <Route
            path="/configs/sellers-json-setup"
            element={
              <Lazyload loader={<Loader />}>
                <SellerJsonFormContainer />
              </Lazyload>
            }
          />
          <Route
            path="/payment/setup"
            element={
              <Lazyload loader={<Loader />}>
                <PaymentContainer page="setup" />
              </Lazyload>
            }
          />
          <Route
            path="/payment/history"
            element={
              <Lazyload loader={<Loader />}>
                <PaymentContainer page="invoice-history" />
              </Lazyload>
            }
          />
          <Route
            path="/payment/payment-history"
            element={
              <Lazyload loader={<Loader />}>
                <PaymentContainer page="payment-history" />
              </Lazyload>
            }
          />
          <Route
            path="/inventory/sites"
            element={
              <Lazyload loader={<Loader />}>
                <SiteListingContainer />
              </Lazyload>
            }
          />
          <Route
            path="/inventory/sites/add"
            element={
              <Lazyload loader={<Loader />}>
                <SiteFormContainer />
              </Lazyload>
            }
          />
          <Route
            path="/inventory/sites/import"
            element={
              <Lazyload loader={<Loader />}>
                <SiteImportContainer />
              </Lazyload>
            }
          />
          <Route
            path="/inventory/sites/:id"
            element={
              <Lazyload loader={<Loader />}>
                <SiteFormContainer />
              </Lazyload>
            }
          />
          <Route
            path="/inventory/sites/:siteid/zones/add"
            element={
              <Lazyload loader={<Loader />}>
                <ZoneFormContainer />
              </Lazyload>
            }
          />
          <Route
            path="/inventory/sites/:siteid/zones/:id/edit"
            element={
              <Lazyload loader={<Loader />}>
                <ZoneFormContainer />
              </Lazyload>
            }
          />
          <Route
            path="/inventory/zones"
            element={
              <Lazyload loader={<Loader />}>
                <ZoneListingContainer />
              </Lazyload>
            }
          />
          <Route
            path="/inventory/zones/import"
            element={
              <Lazyload loader={<Loader />}>
                <ZoneImportContainer />
              </Lazyload>
            }
          />
          <Route
            path="*"
            element={
              <Lazyload loader={<Loader />}>
                <NotFoundContainer />
              </Lazyload>
            }
          />
        </Routes>
      </RoleProvider>
    </AuthProvider>
  );
};

export default PublisherAuthRoutes;
