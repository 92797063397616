const siteListUploadReponseAdapter = r => {
  const sspResult = {
    synchedZones: [],
    notSyncZones: [],
    notFoundZones: [],
    notFoundZonesSsp: [],
    duplicatedZones: [],
  };

  if (r.zones_synced) {
    // Improve
    sspResult.synchedZones = r.zones_synced;
    sspResult.notSyncZones = r.zones_not_synced.internal_errors.not_sync;
    sspResult.notFoundZones = r.zones_not_synced.internal_errors.not_found;
    sspResult.duplicatedZones = r.zones_not_synced.internal_errors.duplicate;
    sspResult.notFoundZonesSsp = r.zones_not_synced.ssp_errors.not_found;
  } else {
    // Equativ or Xandr
    sspResult.synchedZones = r.listZoneSynched;
    sspResult.notSyncZones = r.listZoneNotSynched.notSync;
    sspResult.notFoundZones = r.listZoneNotSynched.notFound;
  }

  return sspResult;
};

export default siteListUploadReponseAdapter;
