import React from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';

import Lazyload from 'utils/Lazyload';

import Loader from 'components/atoms/Loader';

const MaintenanceContainer = React.lazy(() => import('pages/MaintenanceContainer'));

const MaintenanceRouter = () => {
  return (
    <Router>
      <Routes>
        <Route
          path="*"
          element={
            <Lazyload loader={<Loader />}>
              <MaintenanceContainer />
            </Lazyload>
          }
        />
      </Routes>
    </Router>
  );
};

export default MaintenanceRouter;
