/**
 * Format give string to locale date
 * @param  {string} string
 * @param  {string} locale [either fr or en]
 * @return {string}
 */
export const formatDate = (string, format, withTime) => {
  if (!string) {
    return '';
  }

  const date = string instanceof Date ? string : new Date(string);
  if (!date) {
    return '';
  }

  const year = date.getFullYear();
  let [month, day] = [date.getMonth(), date.getDate()];
  month = month + 1 < 10 ? `0${month + 1}` : month + 1;
  day = day < 10 ? `0${day}` : day;

  let time = '';
  if (withTime) {
    let [hours, minutes] = [date.getHours(), date.getMinutes()];
    hours = hours < 10 ? `0${hours}` : hours;
    minutes = minutes < 10 ? `0${minutes}` : minutes;
    time = ` ${hours}:${minutes}`;
  }

  let result = `${year}/${month}/${day}${time}`;
  if (format === 'fr') {
    result = `${day}/${month}/${year}${time}`;
  } else if (format === 'api') {
    result = `${year}-${month}-${day}${time}`;
  }

  return result;
};

/**
 * Get the diff from now
 * @param  {Date} d
 * @return {int}
 */
export const getEvenDaysDiff = d => {
  const now = new Date();
  now.setHours(0, 0, 0, 0);
  const then = new Date(d);
  then.setHours(0, 0, 0, 0);
  return Math.round((now - then) / 8.64e7);
};

/**
 * Get a verbose diff between a date and now
 * @param  {String} string
 * @return {String}
 */
export const displayDateDiff = string => {
  if (!string) {
    return '';
  }

  const date = string instanceof Date ? string : new Date(string);
  if (!date) {
    return '';
  }

  const dayDiff = getEvenDaysDiff(date);

  if (dayDiff === 0) {
    return 'Today';
  }

  if (dayDiff === 1) {
    return 'Yesterday';
  }

  if (dayDiff < 4) {
    return `${dayDiff} days ago`;
  }

  return date.toLocaleDateString('en-GB', { year: 'numeric', month: 'long', day: 'numeric' });
};

/**
 * Compare two dates
 * @param  {String|Date} date1
 * @param  {String|Date} date2
 * @param  {String} method
 * @return {String}
 */
export const compareDates = (date1, date2, method = 'Time') => {
  if (!date1 || !date2) {
    return '';
  }

  const initialDate = date1 instanceof Date ? date1 : new Date(date1);
  const comparedDate = date2 instanceof Date ? date2 : new Date(date2);
  if (!initialDate || !comparedDate) {
    return '';
  }

  const func = `get${method}`;

  if (initialDate[func]() < comparedDate[func]()) {
    return 'sup';
  }
  if (initialDate[func]() > comparedDate[func]()) {
    return 'inf';
  }
  return 'egual';
};

/**
 * Comparison of time in 24format
 * @param  string time1
 * @param  string time2
 * @return string
 */
export const compareTimes = (time1, time2) => {
  const time1Parts = time1.split(':');
  const time1InMinutes = parseInt(time1Parts[0], 10) * 60 + parseInt(time1Parts[1], 10);

  const time2Parts = time2.split(':');
  const time2InMinutes = parseInt(time2Parts[0], 10) * 60 + parseInt(time2Parts[1], 10);

  if (time1InMinutes < time2InMinutes) {
    return 'sup';
  }
  if (time1InMinutes > time2InMinutes) {
    return 'inf';
  }
  return 'egual';
};

/**
 * Capitalize each word of the string
 * @type {string}
 * @return {string}
 */
export const capitalize = string => {
  return string
    .toLowerCase()
    .split(' ')
    .map(s => `${s.charAt(0).toUpperCase()}${s.substring(1)}`)
    .join(' ');
};

/**
 * Transform snake_case string to sentence
 * @param  {string} string
 * @return {string}
 */
export const snakecaseToString = string => {
  return string.split('_').join(' ');
};

/**
 * Transform snake_case string to camelCase
 * @param  {string} string
 * @return {string}
 */
export const snakecaseToCamelCase = string => {
  return string.replace(/_(\w)/g, ($, $1) => $1.toUpperCase());
};

/**
 * Transform snake_case string to PascalCase
 * @param  {string} string
 * @return {string}
 */
export const snakecaseToPascalCase = string => {
  const s = snakecaseToCamelCase(string);
  return `${s.charAt(0).toUpperCase()}${s.substr(1)}`;
};

/**
 * Transform snackcase or camelCase string to sentence
 * @param  {string} string
 * @return {string}
 */
export const normalizeString = string => {
  return string
    .replace(/([a-z0-9])([A-Z])/g, '$1 $2')
    .replace(/_/g, ' ')
    .toLowerCase();
};

/**
 * Get initials of each word in a string
 * @param  {string} string
 * @param  {int} charLimit
 * @return {string}
 */
export const getInitials = (string, charLimit) => {
  if (!string) {
    return '';
  }

  const initials = string.replace(/[^a-zA-Z- ]/g, '').match(/\b\w/g);
  if (!initials) {
    return '';
  }
  const initialsString = initials.join('').toUpperCase();

  return !charLimit ? initialsString : initialsString.substr(0, charLimit);
};

/**
 * Get array of Years
 * @param  {string} from
 * @param  {string} to
 * @return {array}
 */
export const rangeOfYears = (from, to, reverse) => {
  const endYear = to || new Date().getFullYear();
  let startYear = from || new Date().getUTCFullYear();
  const years = [startYear];
  for (let i = startYear; i < endYear; i += 1) {
    years.push((startYear += 1));
  }
  return reverse ? years.sort((a, b) => b - a) : years;
};

/**
 * Decode entity
 * @param  {string} str
 * @return {string}
 */
export const decodeEntity = str => {
  const textarea = document.createElement('textarea');
  textarea.innerHTML = str;
  return textarea.value;
};

/**
 * Parse clean Url
 * @param  {string} url
 * @return {string}
 */
export const getHostnameFromUrl = url => {
  let parsedUrl = url.replace('https://', '').replace('http://', '').split('?')[0];
  [parsedUrl] = parsedUrl.split('/');
  return parsedUrl.split('#')[0];
};

/**
 * Parse str to boolean
 * @param  {string} string
 * @return {bool}
 */
export const strictBoolean = string => {
  let value = string;
  if (typeof value === 'string') {
    value = value.trim().toLowerCase();
  }
  switch (value) {
    case 'true':
    case 'yes':
    case '1':
    case 1:
      return true;

    case 'false':
    case 'no':
    case '0':
    case 0:
    case null:
      return false;

    default:
      return Boolean(value);
  }
};

/**
 * Check if value is a strict integer
 * @param  {*} value
 * @return {bool}
 */
export const isInt = value => {
  return value === parseInt(value, 10);
};

/**
 * Check if value is a valid Email
 * @param  {String} value
 * @param  {bool} strict
 * @return {bool}
 */
export const isEmail = (value, strict) => {
  if (!value) {
    return false;
  }

  const largePattern =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$/;
  const strictPattern = /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
  const pattern = strict ? strictPattern : largePattern;
  if (!pattern.test(value)) {
    return false;
  }
  return true;
};

/**
 * Check if value is a valid alpha
 * @param  {string} value
 * @return {bool}
 */
export const isAlpha = value =>
  RegExp(/^[a-zA-ZàèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸçÇßØøÅåÆæœ_\-']+$/).test(value);

/**
 * Check if value is a valid strict alpha
 * @param  {string} value
 * @return {bool}
 */
export const isStrictAlpha = value => RegExp(/^[a-zA-Z0-9']+$/).test(value);

/**
 * Check if value is a valid company name
 * @param  {string} value
 * @return {bool}
 */
export const isValidCompanyName = value =>
  RegExp(/^(\D+\d*\D*)|(\D*\d*\D+)$/).test(`${value}`.trim());

/**
 * Check if value is a valid tld
 * @param  {string} value
 * @return {bool}
 */
export const isValidTld = value =>
  RegExp(/^[a-z]{2}(\.?[a-z]+)*$/).test(`${value}`.trim()) && `${value}`.trim().length <= 10;

/**
 * Check if value is a valid phone number
 * @param  {string} value
 * @return {bool}
 */
export const isValidPhoneNumber = value =>
  RegExp(/^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s./0-9]*$/g).test(`${value}`.trim());

/**
 * Check if value is a valid hex color
 * @param  {string} value
 * @return {boolean}
 */
export const isValidHexColor = value => {
  if (!value || typeof value !== 'string') {
    return false;
  }

  const color = value.replace('#', '');
  switch (color.length) {
    case 3:
      return /^[0-9A-F]{3}$/i.test(color);
    case 6:
      return /^[0-9A-F]{6}$/i.test(color);
    case 8:
      return /^[0-9A-F]{8}$/i.test(color);
    default:
      return false;
  }
};

/**
 * Convert three digit color to six digit hexcolor
 * @param  {string} value
 * @return {string}
 */
export const cleanHexColor = value => {
  let color = value.replace('#', '');
  if (color.length === 3) {
    color = color
      .split('')
      .map(hex => {
        return `${hex}${hex}`;
      })
      .join('');
  }

  return `#${color}`;
};

/**
 * Build generic file name from file typ
 * @param  {string} type
 * @return {string}
 */
export const getFileNameFromType = type => {
  switch (type) {
    case 'image/jpeg':
    case 'image/jpg':
      return 'image.jpeg';

    case 'image/png':
      return 'image.png';

    case 'image/gif':
      return 'image.gif';

    case 'video/mp4':
      return 'video.mp4';

    default:
      return null;
  }
};

/**
 * Build a flattent array to unflatten tree for parent / children
 * @param  {array}  items
 * @param  {mixed}  id
 * @param  {String} link
 * @return {array}
 */
export const nest = (items, id = null, link = 'parent_id') =>
  items
    .filter(item => item[link] === id)
    .map(item => {
      const children = nest(items, item.id);
      return {
        ...item,
        children: children.length ? children : null,
      };
    });

/**
 * Check if string is valid url via new URL API
 * @param  {string} string
 * @return {bool}
 */
export const isValidUrlViaAPI = string => {
  try {
    return Boolean(new URL(string));
  } catch (e) {
    return false;
  }
};

/**
 * Check if a string is a valid url
 * @param  {string} string
 * @return {bool}
 */
export const isValidUrl = (string, extended = false, strict = true) => {
  if (strict && !isValidUrlViaAPI(string)) {
    return false;
  }
  const protocol = '(https?:\\/\\/)?';

  const domain = '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,})';

  const portAndPath = extended
    ? '(\\:\\d+)?(\\/[-\\w\\d%.~+=;\\$\\[\\]\\{\\}]*)*'
    : '(\\:\\d+)?(\\/[-a-z\\d%_.~+=]*)*';

  const queryParam = extended
    ? "(\\?[\\:\\/;&\\w%_.~+=\\-\\$\\[\\]\\|,'\\{\\}\u2026\\?]*)?"
    : "(\\?[\\:\\/;&a-z\\d%_.~+=\\-\\[\\]\\|,']*)?";

  const anchors = extended
    ? '(\\#[\\:\\/;&\\w%_.~+=\\-\\$\\[\\]\\{\\}\u2026\\?]*)?'
    : '(\\#[-a-z\\d_]*)?';

  const pattern = new RegExp(`^${protocol}${domain}${portAndPath}${queryParam}${anchors}$`, 'i');

  return !!pattern.test(string);
};

/**
 * Check if a string a a valid url that could contain macro
 * @param  {[type]} string [description]
 * @return {[type]}        [description]
 */
export const isValidUrlWithMacro = string => {
  return (
    isValidUrl(string, true, false) ||
    RegExp(/^\$\{(ext|sub):[\w\d_]+\}$/).test(string) ||
    /^\$\{CLICK_URL\}$/.test(string)
  );
};

/**
 * Check if a string is a valid publisher url
 * @param  {string} value
 * @return {bool}
 */
export const isValidWebsiteUrl = value =>
  RegExp(/^(https?:\/\/)([\da-z.-]+).([a-z.]{2,6})([/\w .-]*)*\/?$/).test(value);

/**
 * Get url extension
 * @param  {string} string
 * @return {string}
 */
export const getUrlExt = string => {
  let url = '';
  try {
    url = new URL(string);
  } catch {
    return '';
  }

  return url.pathname ? url.pathname.toLowerCase().split('.').pop() : '';
};

/**
 * Get string between two string
 * @param  {String} str
 * @param  {String} start
 * @param  {String} end
 * @return {String}
 */
export const getStringBetween = (str, start, end) => {
  let newStr = str;
  let i = newStr.indexOf(start);

  if (i >= 0) {
    newStr = newStr.substring(i + start.length);
  } else {
    return '';
  }

  if (end) {
    i = newStr.indexOf(end);
    if (i >= 0) {
      newStr = newStr.substring(0, i);
    } else {
      return '';
    }
  }

  return newStr;
};

/**
 * remove String between two strings
 * @param  {String} str
 * @param  {String} start
 * @param  {String} end
 * @param  {boolean} removeBorders
 * @return {String}
 */
export const removeStringBetween = (str, start, end, removeBorders) => {
  let newStr = str;
  const stringToRemove = getStringBetween(newStr, start, end);
  if (removeBorders) {
    newStr = newStr.replace(start, '');
    newStr = newStr.replace(end, '');
  }
  return newStr.replace(stringToRemove, '');
};

/**
 * Fetch with a timeout
 * @param  {String} url
 * @param  {Object} options
 * @return {Object}
 */
export const fetchWithTimeout = async (url, options = {}) => {
  const { timeout = 8000 } = options;

  const controller = new AbortController();
  const id = setTimeout(() => controller.abort(), timeout);
  const response = await fetch(url, {
    ...options,
    signal: controller.signal,
  });
  clearTimeout(id);
  return response;
};

/**
 * Check if a method is a Promise
 * @param  {function} promise
 * @return {boolean}
 */
export const isPromise = promise => {
  return !!promise && typeof promise.then === 'function';
};

/**
 * Sort array of objects in alphabetical order
 * @param  {array}  array
 * @param  {String} key
 * @return {array}
 */
export const alphabeticalSortForKey = (array, key) => {
  if (!Array.isArray(array) || typeof key !== 'string') {
    return [];
  }

  return [...array].sort((previousItem, nextItem) => {
    const previousItemKey = previousItem[key] ? previousItem[key].toLowerCase() : null;
    const nextItemKey = nextItem[key] ? nextItem[key].toLowerCase() : null;
    if (previousItemKey > nextItemKey) {
      return 1;
    }
    if (previousItemKey < nextItemKey) {
      return -1;
    }
    return 0;
  });
};

/**
 * Detect the current device
 * @param  {string} userAgent
 * @return {string}
 */
export const detectDevice = userAgent => {
  const ua = userAgent !== undefined ? userAgent : navigator.userAgent.toLowerCase();
  if (
    /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(
      ua
    )
  ) {
    return 'tablet';
  }

  if (
    /(mobi|ipod|phone|blackberry|opera mini|fennec|minimo|symbian|psp|nintendo ds|archos|skyfire|puffin|blazer|bolt|gobrowser|iris|maemo|semc|teashark|uzard)/.test(
      ua
    )
  ) {
    return 'mobile';
  }

  return 'desktop';
};

/**
 * Check if a value is empty
 * @param  {array|string} value
 * @return {boolean}
 */
export const isBlank = value => {
  if (typeof value === 'undefined' || value === null) {
    return false;
  }
  if (Array.isArray(value) && value.length === 0) {
    return true;
  }
  return !value;
};
