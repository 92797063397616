import { api } from 'utils/utils-api';

import leadAdapter from './adapters/leadAdapter';

export const apiUrl = `${process.env.LEADS_API_URL}/v1`;
export const buildAuthHeaders = accessToken => ({
  Accept: 'application/vnd.api+json',
  Authorization: `Bearer ${accessToken}`,
});

export const getLeads = (page, search, filters, sort) => {
  let filterParams = '';

  if (search) {
    filterParams += `&filters[search]=${search}`;
  }

  if (filters) {
    if (filters.status) {
      filterParams += `&filters[status]=${filters.status}`;
    }
    if (filters.type) {
      filterParams += `&filters[organization_type]=${filters.type}`;
    }
    if (filters.lastActionBy) {
      filterParams += `&filters[last_action_by]=${filters.lastActionBy}`;
    }
  }

  if (!filters || !filters.status) {
    filterParams += `&filters[status]=unassigned,assigned,refused,unregistered`;
  }

  let sortBy = '';
  if (sort) {
    if (sort.includes('name')) {
      const sortArr = sort.split(':');
      sortBy = `&sortBy[]=website_url:${sortArr[1]}&sortBy[]=company_name:${sortArr[1]}`;
    } else {
      sortBy = `&sortBy[]=${sort}`;
    }
  }

  return api
    .get(`${apiUrl}/leads?relationships[]=user&paginated=1&page=${page}${filterParams}${sortBy}`)
    .then(response => {
      return {
        data: response.data.data.map(leadAdapter),
        meta: response.data.meta,
      };
    })
    .catch(error => Promise.reject(error));
};

export const getLead = id => {
  return api
    .get(`${apiUrl}/leads/${id}?relationships[]=user`)
    .then(response => {
      return leadAdapter(response.data.data);
    })
    .catch(error => Promise.reject(error));
};

export const getLeadByField = async (field, value, token) => {
  return api
    .get(`${apiUrl}/leads/field/${field}/value/${value}`, {
      overridedOptions: token
        ? {
            mode: 'cors',
            headers: buildAuthHeaders(token),
          }
        : null,
    })
    .then(response => {
      return leadAdapter(response.data.data);
    })
    .catch(error => Promise.reject(error));
};

export const putLead = (id, data) =>
  api
    .put(`${apiUrl}/leads/${id}?relationships[]=user`, data)
    .then(response => {
      return leadAdapter(response.data.data);
    })
    .catch(error => Promise.reject(error));

export const postLead = data =>
  api
    .post(`${apiUrl}/leads`, data, {
      overridedOptions: {
        mode: 'cors',
        headers: {
          Accept: 'application/vnd.api+json',
          'Content-Type': 'application/vnd.api+json',
        },
      },
    })
    .then(response => {
      return leadAdapter(response.data.data);
    })
    .catch(error => Promise.reject(error));

export const postUnregisteredLead = data =>
  api
    .post(`${apiUrl}/leads/unregistered`, { ...data, status: 'unregistered' })
    .then(response => {
      return leadAdapter(response.data.data);
    })
    .catch(error => Promise.reject(error));
