import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';

// Initial State
const initialState = {
  local: {
    navOpen: true,
    avatarColor: _.sample(['primary', 'secondary', 'info', 'warning', 'success', 'danger']),
  },
};

/* eslint-disable no-param-reassign */
const configsSlice = createSlice({
  name: 'configs',
  initialState,
  reducers: {
    setNavOpen(state, action) {
      const show = action.payload;
      state.local.navOpen = show;
    },
    configsCleared(state) {
      state.local = initialState.local;
    },
  },
});
/* eslint-enable no-param-reassign */

export const { setNavOpen, configsCleared } = configsSlice.actions;

// Selectors
export const selectConfigs = state => state.configs;
export const selectLocalConfigs = state => state.configs.local;

const configsReducer = configsSlice.reducer;
export default configsReducer;
