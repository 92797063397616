import { combineReducers } from 'redux';

import alertReducer from 'redux/features/alert/alertSlice';
import configsReducer from 'redux/features/configs/configsSlice';
import countriesReducer from 'redux/features/countries/countriesSlice';
import leadsReducer from 'redux/features/leads/leadsSlice';
import sellersJsonsReducer from 'redux/features/sellersJsons/sellersJsonsSlice';
import sitesReducer from 'redux/features/sites/sitesSlice';
import usersReducer from 'redux/features/users/usersSlice';
import zonesReducer from 'redux/features/zones/zonesSlice';

const rootReducer = combineReducers({
  users: usersReducer,
  alert: alertReducer,
  configs: configsReducer,
  countries: countriesReducer,
  leads: leadsReducer,
  sites: sitesReducer,
  zones: zonesReducer,
  sellersJsons: sellersJsonsReducer,
});

export default rootReducer;
