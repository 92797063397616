import { api } from 'utils/utils-api';

import connectorAdapter from './adapters/connectorAdapter';
import externalZonePlacementAdapter from './adapters/externalZonePlacementAdapter';
import siteListUploadReponseAdapter from './adapters/siteListUploadReponseAdapter';
import zoneAdapter from './adapters/zoneAdapter';
import zoneHostnameAdapter from './adapters/zoneHostnameAdapter';
import zonePrebidConfigAdapter from './adapters/zonePrebidConfigAdapter';

export const apiUrl = `${process.env.SAS_API_URL}/api/v1`;
export const apiUrlV2 = `${process.env.SAS_API_URL}/api/v2`;
export const apiUrlV3 = `${process.env.SAS_API_URL}/api/v3`;

export const getZones = (page, search, filters, relationships, perPage = 50) => {
  const params = search ? `&filters[search]=${search}` : '';

  let filterParams = '';
  if (filters) {
    if (filters.site_id) {
      filterParams += `&filters[site_id]=${filters.site_id}`;
    }
    if (filters.ad_id) {
      filterParams += `&filters[ad_id]=${filters.ad_id}`;
    }
    if (filters.devices) {
      filterParams += `&filters[devices]=${filters.devices}`;
    }
    if (filters.country) {
      filterParams += `&filters[country]=${filters.country}`;
    }
    if (filters.traffic_types) {
      filterParams += `&filters[traffic_types]=${filters.traffic_types}`;
    }
    if (filters.excluded_traffic_types) {
      filterParams += `&filters[excluded_traffic_types]=${filters.excluded_traffic_types}`;
    }
    if (filters.placement_types) {
      filterParams += `&filters[placement_types]=${filters.placement_types}`;
    }
    if (filters.ad_types) {
      filterParams += `&filters[ad_types]=${filters.ad_types}`;
    }
  }

  let relationshipsParams = '';
  if (relationships) {
    if (relationships.sites) {
      relationshipsParams += '&relationships[]=sites';
    }
    if (relationships.categories) {
      relationshipsParams += '&relationships[]=categories';
    }
    if (relationships.zone_price_history) {
      relationshipsParams += '&relationships[]=zone_price_history';
    }
  }

  return api
    .get(
      `${apiUrlV3}/zones?paginated=1&per_page=${perPage}&page=${page}${params}${filterParams}${relationshipsParams}`,
      { usePublisherTokenIfExists: true }
    )
    .then(response => {
      return response.data;
    })
    .catch(error => Promise.reject(error));
};

export const getZone = id =>
  api
    .get(
      `${apiUrlV3}/zones/${id}?relationships[]=setup_zone&relationships[]=iab_categories&with[]=adserving_count`,
      {
        usePublisherTokenIfExists: true,
      }
    )
    .then(response => {
      return zoneAdapter(response.data.data);
    })
    .catch(error => Promise.reject(error));

export const getZoneHistory = id =>
  api
    .get(`${apiUrlV3}/zones/${id}/history`, {
      usePublisherTokenIfExists: true,
    })
    .then(response => {
      return response.data.data;
    })
    .catch(error => Promise.reject(error));

export const getZoneHostnames = (sasAccessToken, id, page, search, filters) => {
  const params = search ? `&filters[search]=${search}` : '';

  let filterParams = '';
  if (filters) {
    if (filters.status) {
      filterParams += `&filters[status]=${filters.status}`;
    }
    if (filters.device) {
      filterParams += `&filters[device]=${filters.device}`;
    }
  }

  return api
    .get(
      `${apiUrl}/zones/${id}/hostnames?relationships[]=zones&relationships[]=sites&paginated=1&page=${page}${params}${filterParams}`,
      { extendedHeaders: { Authorization: `Bearer ${sasAccessToken}` } }
    )
    .then(response => {
      return {
        data: response.data.data.map(zoneHostnameAdapter),
        meta: response.data.meta,
      };
    })
    .catch(error => Promise.reject(error));
};

export const getExternalZonePlacements = (sasAccessToken, id, page, search, filters) => {
  const params = search ? `&filters[search]=${search}` : '';

  let filterParams = '';
  if (filters) {
    if (filters.status) {
      filterParams += `&filters[status]=${filters.status}`;
    }
    if (filters.device) {
      filterParams += `&filters[device]=${filters.device}`;
    }
  }

  return api
    .get(
      `${apiUrl}/zones/${id}/external-placements?paginated=1&page=${page}${params}${filterParams}`,
      { extendedHeaders: { Authorization: `Bearer ${sasAccessToken}` } }
    )
    .then(response => {
      return {
        data: response.data.data.map(externalZonePlacementAdapter),
      };
    })
    .catch(error => Promise.reject(error));
};

export const getSingleZoneHostname = (sasAccessToken, id) =>
  api
    .get(`${apiUrl}/hostnames/${id}`, {
      extendedHeaders: { Authorization: `Bearer ${sasAccessToken}` },
    })
    .then(response => {
      return zoneHostnameAdapter(response.data.data);
    })
    .catch(error => Promise.reject(error));

export const getSingleExternalZonePlacement = (sasAccessToken, id) =>
  api
    .get(`${apiUrl}/external-zone-placements/${id}`, {
      extendedHeaders: { Authorization: `Bearer ${sasAccessToken}` },
    })
    .then(response => {
      return externalZonePlacementAdapter(response.data.data);
    })
    .catch(error => Promise.reject(error));

export const getZonePrebidConfig = (sasAccessToken, id) =>
  api
    .get(`${apiUrl}/zones/${id}/prebid-config`, {
      extendedHeaders: { Authorization: `Bearer ${sasAccessToken}` },
    })
    .then(response => {
      return { config: zonePrebidConfigAdapter(response.data) };
    })
    .catch(error => Promise.reject(error));

export const putZonePrebidConfig = (id, prebidConfigId, data) =>
  api
    .put(
      `${apiUrlV3}/zones/${id}/prebid-config/${prebidConfigId}`,
      {
        ...data,
        ssp_config: JSON.stringify(data.ssp_config),
        bid_sizes: JSON.stringify(data.bid_sizes),
      },
      { usePublisherTokenIfExists: true }
    )
    .then(response => {
      return { config: zonePrebidConfigAdapter(response.data) };
    })
    .catch(error => Promise.reject(error));

export const postZonePrebidConfig = (id, data) =>
  api
    .post(`${apiUrlV3}/zones/${id}/prebid-config`, {
      ...data,
      ssp_config: JSON.stringify(data.ssp_config),
      bid_sizes: JSON.stringify(data.bid_sizes),
    })
    .then(response => {
      return { config: zonePrebidConfigAdapter(response.data) };
    })
    .catch(error => Promise.reject(error));

export const putZoneHostnames = (id, data) =>
  api
    .put(`${apiUrlV3}/hostnames/${id}`, data)
    .then(response => {
      return zoneHostnameAdapter(response.data.data);
    })
    .catch(error => Promise.reject(error));

export const postZoneHostnames = data =>
  api
    .post(`${apiUrlV3}/bulk/hostnames`, data)
    .then(response => {
      return response.data;
    })
    .catch(error => Promise.reject(error));

export const putExternalZonePlacement = (id, data) =>
  api
    .put(`${apiUrlV3}/external-zone-placements/${id}`, data)
    .then(response => {
      return externalZonePlacementAdapter(response.data.data);
    })
    .catch(error => Promise.reject(error));

export const postExternalZonePlacement = data =>
  api
    .post(`${apiUrlV3}/external-zone-placements`, data)
    .then(response => {
      return externalZonePlacementAdapter(response.data.data);
    })
    .catch(error => Promise.reject(error));

export const postExternalZonePlacements = data =>
  api
    .post(`${apiUrlV3}/bulk/external-zone-placements`, data)
    .then(response => {
      return response.data;
    })
    .catch(error => Promise.reject(error));

export const postZone = data =>
  api
    .post(
      `${apiUrlV3}/zones?relationships[]=setup_zone&relationships[]=iab_categories&with[]=adserving_count`,
      data,
      {
        usePublisherTokenIfExists: true,
      }
    )
    .then(response => {
      return zoneAdapter(response.data.data);
    })
    .catch(error => Promise.reject(error));

export const postZoneCopy = (id, siteId) => {
  return api
    .post(
      `${apiUrlV3}/zones/${id}/duplicate?relationships[]=setup_zone&relationships[]=iab_categories`,
      {
        site_id: siteId,
      },
      { usePublisherTokenIfExists: true }
    )
    .then(response => {
      return zoneAdapter(response.data.data);
    })
    .catch(error => Promise.reject(error));
};

export const putZone = (id, data) =>
  api
    .put(
      `${apiUrlV3}/zones/${id}?relationships[]=setup_zone&relationships[]=iab_categories&with[]=adserving_count`,
      data,
      { usePublisherTokenIfExists: true }
    )
    .then(response => {
      return zoneAdapter(response.data.data);
    })
    .catch(error => Promise.reject(error));

export const putZoneMonetization = (sasAccessToken, data) =>
  api
    .put(`${apiUrl}/zones/monetization`, data, {
      extendedHeaders: { Authorization: `Bearer ${sasAccessToken}` },
    })
    .then(response => {
      return response.data;
    })
    .catch(error => Promise.reject(error));

export const putZoneConnectors = (id, data) =>
  api
    .put(`${apiUrlV3}/zones/${id}/connectors`, { connectors: data })
    .then(response => {
      return {
        data: response.data.data.map(connectorAdapter),
      };
    })
    .catch(error => Promise.reject(error));

export const putZoneAdservings = (id, data) =>
  api
    .put(`${apiUrlV3}/zones/${id}/adservings`, { adservings: data })
    .then(response => {
      return {
        data: response.data.data.map(connectorAdapter),
      };
    })
    .catch(error => Promise.reject(error));

export const putSitelistSsp = (sasAccessToken, data) => {
  const sspData = {
    smartadserver: {
      urlDirect: `/sitelist-upload/smartadserver/insertion/${data.insertion_id}`,
      urlDeal: `/sitelist-upload/smartadserver/deal/${data.deal_id}`,
      data: {},
    },
    appnexus: {
      urlDirect: `/sitelist-upload/appnexus/direct/${data.line_item_id}`,
      urlDeal: `/sitelist-upload/appnexus/deal/${data.deal_id}`,
      data: {
        account: data.account,
      },
    },
    improve: {
      urlDirect: `/sitelist-upload/improve/campaigns/${data.direct_id}/line-items/${data.line_item_id}`,
      urlDeal: `/sitelist-upload/improve/campaigns/${data.deal_id}/line-items/${data.line_item_id}`,
      data: {
        device: data.device,
        is_deal: data.campaign_type === 'deal',
        blacklisted_keys: data.blacklisted_keys,
        upload_type: data.upload_type,
      },
    },
  };

  const ssp = sspData[data.ssp_name];
  const url = data.campaign_type === 'deal' ? ssp.urlDeal : ssp.urlDirect;

  const reqData = {
    ...ssp.data,
    keys: data.keys,
    keys_type: data.keys_type,
    include_external_placements: data.include_external_placements,
  };

  return api
    .put(`${apiUrl}${url}`, reqData, {
      extendedHeaders: { Authorization: `Bearer ${sasAccessToken}` },
    })
    .then(response => {
      return {
        ...response.data,
        data: siteListUploadReponseAdapter(response.data.data),
      };
    })
    .catch(error => Promise.reject(error));
};

export const postZoneUpdateWithCsv = data => {
  const formData = new FormData();
  Object.entries(data).forEach(([key, value]) => formData.append(key, value));

  return api
    .post(`${apiUrlV3}/zones/csv-update`, formData, {
      usePublisherTokenIfExists: true,
      extendedHeaders: {
        'Content-type': 'multipart/form-data',
      },
    })
    .then(response => {
      return response.data?.data ? Object.values(response.data.data) : [];
    })
    .catch(error => Promise.reject(error));
};

export const postZonePassbackWithCsv = data => {
  const formData = new FormData();
  Object.entries(data).forEach(([key, value]) => formData.append(key, value));

  return api
    .post(`${apiUrlV3}/zones/csv-update-passback`, formData, {
      usePublisherTokenIfExists: true,
      extendedHeaders: {
        'Content-type': 'multipart/form-data',
      },
    })
    .then(response => {
      return response.data?.data ? Object.values(response.data.data) : [];
    })
    .catch(error => Promise.reject(error));
};

export const getZoneListAsCsv = publisherId =>
  api
    .get(`${apiUrlV3}/zones/export${publisherId ? `?filters[publisher]=${publisherId}` : ''}`, {
      usePublisherTokenIfExists: true,
      extendedHeaders: {
        'Content-type': 'multipart/form-data',
      },
    })
    .then(response => {
      return response.data;
    })
    .catch(error => Promise.reject(error));

export const getZoneImportOuput = publisherId =>
  api
    .get(
      `${apiUrlV3}/zones/import-output${publisherId ? `?filters[publisher]=${publisherId}` : ''}`,
      {
        usePublisherTokenIfExists: true,
        extendedHeaders: {
          'Content-type': 'multipart/form-data',
        },
      }
    )
    .then(response => {
      return {
        date: response.data?.data.date,
        content: response.data?.data.content,
      };
    })
    .catch(error => Promise.reject(error));

export const getBuycard = id =>
  api
    .get(`${apiUrlV3}/buycards?zones=${id}&with_hierarchy=1`)
    .then(response => {
      return {
        data: response.data?.data?.attributes?.buycard_units,
        meta: response.data?.meta,
      };
    })
    .catch(error => Promise.reject(error));
