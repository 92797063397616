import React from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';

import PublisherAuthRoutes from 'routers/routes/PublisherAuthRoutes';

import Lazyload from 'utils/Lazyload';

import Loader from 'components/atoms/Loader';

const RegistrationFormContainer = React.lazy(() =>
  import('pages/publishers/RegistrationFormContainer')
);
const LeadJoinFormContainer = React.lazy(() => import('pages/leads/LeadJoinFormContainer'));
const HomePageContainer = React.lazy(() => import('pages/external/HomePageContainer'));
const NotFoundPublicContainer = React.lazy(() => import('pages/NotFoundPublicContainer'));
const LoginContainer = React.lazy(() => import('pages/auth/LoginContainer'));
const ResetPasswordContainer = React.lazy(() => import('pages/auth/ResetPasswordContainer'));
const ForgotPasswordContainer = React.lazy(() => import('pages/auth/ForgotPasswordContainer'));

const ExternalRouter = () => {
  return (
    <Router>
      <Routes>
        <Route path="/publishers/*" element={<PublisherAuthRoutes />} />
        <Route
          path="/login"
          element={
            <Lazyload loader={<Loader />}>
              <LoginContainer type="azerion" />
            </Lazyload>
          }
          exact
        />
        <Route
          path="/forgot-password"
          element={
            <Lazyload loader={<Loader />}>
              <ForgotPasswordContainer type="azerion" />
            </Lazyload>
          }
          exact
        />
        <Route
          path="/reset-password"
          element={
            <Lazyload loader={<Loader />}>
              <ResetPasswordContainer type="azerion" />
            </Lazyload>
          }
          exact
        />
        <Route
          path="/update-password"
          element={
            <Lazyload loader={<Loader />}>
              <ResetPasswordContainer forceNew type="azerion" />
            </Lazyload>
          }
          exact
        />
        <Route
          path="/register"
          element={
            <Lazyload loader={<Loader />}>
              <RegistrationFormContainer />
            </Lazyload>
          }
          exact
        />
        <Route
          path="/"
          element={
            <Lazyload loader={<Loader />}>
              <HomePageContainer />
            </Lazyload>
          }
          exact
        />
        <Route
          path="/leads"
          element={
            <Lazyload loader={<Loader />}>
              <LeadJoinFormContainer />
            </Lazyload>
          }
          exact
        />
        <Route
          path="*"
          element={
            <Lazyload loader={<Loader />}>
              <NotFoundPublicContainer />
            </Lazyload>
          }
        />
      </Routes>
    </Router>
  );
};

export default ExternalRouter;
