const externalZonePlacementAdapter = ezp => {
  if (ezp.id && ezp.attributes) {
    return {
      id: ezp.id,
      zone_name:
        ezp.relationships?.zones && ezp.relationships.zones?.attributes
          ? ezp.relationships.zones.attributes.name
          : '',
      country:
        ezp.relationships?.zones &&
        ezp.relationships.zones?.relationships &&
        ezp.relationships.zones.relationships?.sites
          ? ezp.relationships.zones.relationships.sites.country
          : '',
      ...ezp.attributes,
      network_id: ezp.relationships.improve_placement_data
        ? ezp.relationships.improve_placement_data.attributes.publisher_id
        : '',
      network_name: ezp.relationships.improve_placement_data
        ? ezp.relationships.improve_placement_data.attributes.publisher_name
        : '',
      relationships: {
        ...ezp.relationships,
      },
    };
  }
  return {};
};

export default externalZonePlacementAdapter;
