import PropTypes from 'prop-types';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useAuth } from 'context/AuthProvider';

import usePrevious from 'hooks/usePrevious';

import RoleContext from './RoleContext';

const RoleProvider = ({ children, loader, roleSlugs }) => {
  const { authUser, checkRole, getAuthUserRoles } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthorized, setIsAuthorized] = useState(null);
  const [checkingAuthorization, setCheckingAuthorization] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const prevLocation = usePrevious(location);

  const roles = authUser?.relationships?.roles?.slugs || [];

  const hasRole = async value => {
    const hasWantedRole = await checkRole(value);
    return hasWantedRole;
  };

  const handleAuthorization = async slugs => {
    setCheckingAuthorization(true);
    const slugsArray = [...slugs];
    const currentIndex = 0;
    const hasOneOfRoles = await hasRole(slugsArray[currentIndex]);

    if (hasOneOfRoles) {
      setIsAuthorized(hasOneOfRoles);
      setCheckingAuthorization(false);
    } else if (slugsArray.length === 1) {
      setIsAuthorized(false);
      setCheckingAuthorization(false);
    } else {
      slugsArray.splice(currentIndex, 1);
      await handleAuthorization(slugsArray);
    }
  };

  const contextValue = useMemo(() => ({ roles, hasRole, isAuthorized }), [roles, isAuthorized]);

  useEffect(() => {
    if (isAuthorized == null || location !== prevLocation) {
      if (!checkingAuthorization) {
        handleAuthorization(roleSlugs);
      }
      getAuthUserRoles();
    }
  }, [authUser, location]);

  useEffect(() => {
    if (isLoading && isAuthorized !== null) {
      if (isAuthorized) {
        setIsLoading(false);
      } else {
        navigate('/not-authorized', { replace: true });
      }
    }
  }, [isAuthorized]);

  if (isLoading || !isAuthorized) {
    return loader;
  }

  return <RoleContext.Provider value={contextValue}>{children}</RoleContext.Provider>;
};

RoleProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.node, PropTypes.elementType])
    .isRequired,
  loader: PropTypes.oneOfType([PropTypes.element, PropTypes.node, PropTypes.elementType])
    .isRequired,
  roleSlugs: PropTypes.arrayOf(
    PropTypes.oneOf([
      'internal',
      'publisher',
      'admin',
      'creative_manager',
      'creative_manager_restricted',
    ])
  ).isRequired,
};

const useRole = () => useContext(RoleContext);

export { RoleProvider, useRole };
