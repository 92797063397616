import { createAsyncThunk } from '@reduxjs/toolkit';

import { withToastForError } from 'redux/sliceTools';

import {
  bulkImportSites,
  getBuycard,
  getSite,
  getSiteHistory,
  getSites,
  postSite,
  putBuycard,
  putSite,
} from 'services/site-service';

export const fetchSites = createAsyncThunk(
  'sites/fetchSites',
  withToastForError(async ({ page, search, filters }) => {
    const response = await getSites(page, search, filters);
    return response;
  })
);

export const globalSearchSites = createAsyncThunk(
  'sites/globalSearchSites',
  withToastForError(async ({ page, search }) => {
    const response = await getSites(page, search);
    return response;
  })
);

export const fetchSite = createAsyncThunk(
  'sites/fetchSite',
  withToastForError(async ({ id }) => {
    const response = await getSite(id);
    return response;
  })
);

export const fetchSiteHistory = createAsyncThunk(
  'sites/fetchSiteHistory',
  withToastForError(async ({ id }) => {
    const response = await getSiteHistory(id);
    return response;
  })
);

export const fetchSiteBuycard = createAsyncThunk(
  'sites/fetchSiteBuycard',
  withToastForError(async ({ id }) => {
    const response = await getBuycard(id);
    return response;
  })
);

export const createSite = createAsyncThunk(
  'sites/createSite',
  withToastForError(async ({ data }) => {
    const response = await postSite(data);
    return response;
  })
);

export const updateSite = createAsyncThunk(
  'sites/updateSite',
  withToastForError(async ({ id, data }) => {
    const response = await putSite(id, data);
    return response;
  })
);

export const updateSiteBuycard = createAsyncThunk(
  'sites/updateSiteBuycard',
  withToastForError(async ({ id, data }) => {
    const response = await putBuycard(id, data);
    return response;
  })
);

export const importSites = createAsyncThunk(
  'sites/importSites',
  withToastForError(async ({ data }) => {
    const response = await bulkImportSites(data);
    return response;
  })
);
