const zoneHostnameAdapter = z => {
  if (z.id && z.attributes) {
    return {
      id: z.id,
      full_hostname: `${z.attributes.subdomain ? z.attributes.subdomain : '*'}.${
        z.attributes.domain
      }.${z.attributes.tld}`,
      zone_name:
        z.relationships?.zones && z.relationships.zones?.attributes
          ? z.relationships.zones.attributes.name
          : '',
      country:
        z.relationships?.zones &&
        z.relationships.zones?.relationships &&
        z.relationships.zones.relationships?.sites
          ? z.relationships.zones.relationships.sites.country
          : '',
      ...z.attributes,
      relationships: {
        ...z.relationships,
      },
    };
  }
  return {};
};

export default zoneHostnameAdapter;
