const billingAccountAdapter = b => {
  if (b.id && b.attributes) {
    return {
      id: b.id,
      ...b.attributes,
    };
  }
  return {};
};

export default billingAccountAdapter;
