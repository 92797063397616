import { api } from 'utils/utils-api';

import siteAdapter from './adapters/siteAdapter';

export const apiUrl = `${process.env.SAS_API_URL}/api/v1`;
export const apiUrlV2 = `${process.env.SAS_API_URL}/api/v2`;
export const apiUrlV3 = `${process.env.SAS_API_URL}/api/v3`;

export const getSites = (page, search, filters) => {
  const params = search ? `&filters[search]=${search}` : '';

  let filterParams = '';
  if (filters) {
    if (filters.status && filters.status !== 'all') {
      filterParams += `&filters[status]=${filters.status}`;
    }
    if (filters.publisher) {
      filterParams += `&filters[publisher]=${filters.publisher}`;
    }
    if (filters.country) {
      filterParams += `&filters[country]=${filters.country}`;
    }
  }

  return api
    .get(
      `${apiUrlV3}/sites?paginated=1&per_page=10&page=${page}${params}${filterParams}&relationships[]=company`,
      { usePublisherTokenIfExists: true }
    )
    .then(response => {
      return response.data;
    })
    .catch(error => Promise.reject(error));
};

export const getSite = id =>
  api
    .get(
      `${apiUrlV3}/sites/${id}?relationships[]=categories&relationships[]=publishers&relationships[]=improve_sync`,
      { usePublisherTokenIfExists: true }
    )
    .then(response => {
      return siteAdapter(response.data.data);
    })
    .catch(error => Promise.reject(error));

export const getSiteHistory = id =>
  api
    .get(`${apiUrlV3}/sites/${id}/history`, {
      usePublisherTokenIfExists: true,
    })
    .then(response => {
      return response.data.data;
    })
    .catch(error => Promise.reject(error));

export const getBuycard = id =>
  api
    .get(`${apiUrlV3}/sites/${id}/buycards?with_hierarchy=1`)
    .then(response => {
      return {
        data: response.data?.data?.attributes?.buycard_units,
        meta: response.data?.meta,
      };
    })
    .catch(error => Promise.reject(error));

export const getSiteCampanyName = CompanyId =>
  api
    .get(`${apiUrlV3}/sites/company-name/${CompanyId}`, {
      usePublisherTokenIfExists: true,
    })
    .then(response => {
      return response.data.data;
    })
    .catch(error => Promise.reject(error));

export const getSiteAdsTxt = id =>
  api
    .get(`${apiUrl}/site/${id}/adstxt`, {
      overridedOptions: {
        mode: 'cors',
        headers: {
          Accept: 'application/vnd.api+json',
        },
      },
    })
    .then(response => {
      return {
        multiContent: response.data?.content[0] || '',
        singleContent: response.data?.content[1] || '',
      };
    })
    .catch(error => Promise.reject(error));

export const postSite = data =>
  api
    .post(`${apiUrlV3}/sites`, data, { usePublisherTokenIfExists: true })
    .then(response => {
      return siteAdapter(response.data.data);
    })
    .catch(error => Promise.reject(error));

export const putSite = (id, data) =>
  api
    .put(
      `${apiUrlV3}/sites/${id}?relationships[]=categories&relationships[]=publishers&relationships[]=improve_sync`,
      data,
      { usePublisherTokenIfExists: true }
    )
    .then(response => {
      return siteAdapter(response.data.data);
    })
    .catch(error => Promise.reject(error));

export const bulkImportSites = data => {
  const formData = new FormData();
  Object.entries(data).forEach(([key, value]) => formData.append(key, value));

  return api
    .post(`${apiUrlV3}/sites/import`, formData, {
      usePublisherTokenIfExists: true,
      extendedHeaders: {
        'Content-type': 'multipart/form-data',
      },
    })
    .then(response => {
      return response.data?.data ? Object.values(response.data.data) : [];
    })
    .catch(error => Promise.reject(error));
};

export const getSiteImportOuput = userId =>
  api
    .get(`${apiUrlV3}/sites/import-output/${userId}`, {
      usePublisherTokenIfExists: true,
      extendedHeaders: {
        'Content-type': 'multipart/form-data',
      },
    })
    .then(response => {
      return {
        date: response.data?.data.date,
        content: response.data?.data.content,
      };
    })
    .catch(error => Promise.reject(error));

export const putBuycard = (id, data) =>
  api
    .put(`${apiUrlV3}/sites/${id}/buycards`, data)
    .then(response => {
      return {
        meta: response.data?.meta,
      };
    })
    .catch(error => Promise.reject(error));
