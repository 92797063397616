import { captureException, captureMessage, withScope } from '@sentry/browser';

import { organizationIdToLogs } from 'constants/globals';

import { getLocationInfo } from 'utils/authHelpers';
import { formatDate } from 'utils/utils';
import { api } from 'utils/utils-api';

import billingAccountAdapter from './adapters/billingAccountAdapter';
import publisherAdapter from './adapters/publisherAdapter';
import sellersJsonAdapter from './adapters/sellersJsonAdapter';

export const sasApiUrl = `${process.env.SAS_API_URL}/api/v1`;
export const umsApiUrlV1 = `${process.env.UMS_API_URL}/api/v1`;
export const umsApiUrlV2 = `${process.env.UMS_API_URL}/api/v2`;
export const publisherApiUrlV1 = `${process.env.PUBLISHERS_API_URL}/v1`;
export const publisherApiUrlV2 = `${process.env.PUBLISHERS_API_URL}/v2`;
export const globalReportApiUrl = `${process.env.GLOBAL_REPORTS_API_URL}/v1`;
export const billingApiUrl = `${process.env.BILLING_API_URL}/v1`;
export const sboApiUrl = `${process.env.SBO_API_URL}/api/v1`;

export const getPublishers = (page, search) => {
  const pagination = page ? `paginated=1&page=${page}` : '';
  const params = search ? `${pagination ? '&' : ''}search=${search}` : '';

  return api
    .get(
      `${umsApiUrlV2}/organizations?${pagination}${params}&relationships[]=activated-users&excludeIds=0`
    )
    .then(response => {
      return response.data;
    })
    .catch(error => Promise.reject(error));
};

export const getPublisherByField = (field, value) => {
  return api
    .get(`${umsApiUrlV2}/organizations/field/${field}/value/${value}`)
    .then(response => {
      return response.data;
    })
    .catch(error => Promise.reject(error));
};

export const getTos = (organizationId, email) => {
  return api
    .get(
      `${publisherApiUrlV2}/tos-check?organizationId=${organizationId}&email=${encodeURIComponent(
        email
      )}`
    )
    .then(response => {
      return response.data;
    })
    .catch(error => Promise.reject(error));
};

export const getTosInformation = id => {
  return api
    .get(`${sboApiUrl}/publishers/v2/publishers/${id}/tos-information`)
    .then(response => {
      return response.data.data;
    })
    .catch(error => Promise.reject(error));
};

export const getAllPublishers = (page, search, filters, sort) => {
  let filterParams = '&filters[only_externs]=1';

  if (search) {
    filterParams += `&filters[search]=${search}`;
  }

  if (filters) {
    if (filters.country) {
      filterParams += `&filters[country]=${filters.country}`;
    }
    if (filters.created_at) {
      filterParams += `&filters[created_at]=${filters.created_at}`;
    }
    if (filters.status) {
      filterParams += `&filters[is_active]=${filters.status === 'active' ? 1 : 0}`;
    }
  }

  let sortBy = '';
  if (sort) {
    sortBy = `&sortBy[]=${sort}`;
  }

  return api
    .get(
      `${sboApiUrl}/publishers/v2/publishers?paginated=1&page=${page}${filterParams}${sortBy}&with_site_count=1`
    )
    .then(response => {
      return {
        data: response.data.data.map(publisherAdapter),
        meta: response.data.meta,
      };
    })
    .catch(error => Promise.reject(error));
};

export const getPublisher = id => {
  return api
    .get(`${sboApiUrl}/publishers/v1/publishers/${id}`)
    .then(response => {
      return publisherAdapter(response.data.data);
    })
    .catch(error => Promise.reject(error));
};

export const getPublisherAccess = async organizationId => {
  const { clientId } = await getLocationInfo();
  return api
    .post(`${umsApiUrlV2}/token/generate-access/${organizationId}`, { client_id: clientId })
    .then(response => {
      return response.data;
    })
    .catch(error => Promise.reject(error));
};

export const getExisitingPublisherName = name => {
  return api
    .get(`${publisherApiUrlV2}/publishers/exists/${name}`, { overridedOptions: {} })
    .then(response => {
      return response.data;
    })
    .catch(error => Promise.reject(error));
};

export const getPublisherSellersJson = publisherId => {
  return api
    .get(`${publisherApiUrlV2}/publishers/${publisherId}/seller`, {
      usePublisherTokenIfExists: true,
    })
    .then(response => {
      return sellersJsonAdapter(response.data.data);
    })
    .catch(error => Promise.reject(error));
};

export const getPublisherSites = legacyUid => {
  return api
    .get(`${publisherApiUrlV2}/publishers/${legacyUid}/sites`, { usePublisherTokenIfExists: true })
    .then(response => {
      return response.data;
    })
    .catch(error => Promise.reject(error));
};

export const postPublisher = data =>
  api
    .post(`${publisherApiUrlV1}/publishers`, data, {
      overridedOptions: {
        mode: 'cors',
        headers: {
          Accept: 'application/vnd.api+json',
          'Content-Type': 'application/vnd.api+json',
        },
      },
    })
    .then(response => {
      return response.data;
    })
    .catch(error => Promise.reject(error));

export const getPublisherBillingAccount = organizationId => {
  return api
    .get(`${publisherApiUrlV2}/publishers/${organizationId}/billing-account`, {
      usePublisherTokenIfExists: true,
    })
    .then(response => {
      return response.data;
    })
    .catch(error => Promise.reject(error));
};

export const getPublisherDetailedBillingAccount = id => {
  return api
    .get(`${sboApiUrl}/billing/organization/${id}/accounts-billing?withArchived=1`)
    .then(response => {
      const list = response.data.data;
      return {
        current: billingAccountAdapter(list[0]),
        archived: list.slice(1).map(billingAccountAdapter),
      };
    })
    .catch(error => Promise.reject(error));
};

export const getPublisherRevenuesUrlViaPost = (
  organizationId,
  type,
  filters,
  controller = null
) => {
  return api
    .post(
      `${globalReportApiUrl}/visualizations/publishers/${organizationId}/revenues/${type}`,
      filters,
      {
        usePublisherTokenIfExists: true,
        extendedOptions: !controller ? {} : { signal: controller.signal },
      }
    )
    .then(response => {
      return response.data.data;
    })
    .catch(error => Promise.reject(error));
};

export const getPublisherRevenuesUrl = (organizationId, type, filters, controller = null) => {
  const rebuildFilters = { ...filters };
  if (filters.date) {
    rebuildFilters.from = `${formatDate(
      new Date(filters.date.getFullYear(), filters.date.getMonth(), 1),
      'api'
    )}T00:00:00`;
    rebuildFilters.to = `${formatDate(
      new Date(filters.date.getFullYear(), filters.date.getMonth() + 1, 1),
      'api'
    )}T00:00:00`;
    delete rebuildFilters.date;
  }

  if (filters.sites.length > 1500) {
    return getPublisherRevenuesUrlViaPost(organizationId, type, rebuildFilters);
  }

  let params = '';
  Object.keys(rebuildFilters).forEach(key => {
    params += `${key}=${rebuildFilters[key]}&`;
  });

  return api
    .get(
      `${globalReportApiUrl}/visualizations/publishers/${organizationId}/revenues/${type}?${params}`,
      {
        usePublisherTokenIfExists: true,
        extendedOptions: !controller ? {} : { signal: controller.signal },
      }
    )
    .then(response => {
      if (organizationIdToLogs.includes(organizationId)) {
        withScope(scope => {
          scope.setTransactionName(
            `[${process.env.SENTRY_ENV}]: getPublisherRevenuesUrl - Looker Success for publishers ${organizationId}`
          );
          scope.setExtra('data', {
            context: response.data.data,
          });
          captureMessage('Looker Success for publishers');
        });
      }
      return response.data.data;
    })
    .catch(error => {
      withScope(scope => {
        scope.setTransactionName(
          `[${process.env.SENTRY_ENV}]: getPublisherRevenuesUrl - Error during request looker for publishers ${organizationId}`
        );
        scope.setExtra('data', {
          context: filters,
        });
        captureException(error);
      });
      return Promise.reject(error);
    });
};

export const getPublisherRevenuesCsvViaPost = (organizationId, filters) => {
  return api
    .post(
      `${globalReportApiUrl}/visualizations/publishers/${organizationId}/revenues/raw/csv`,
      filters,
      { usePublisherTokenIfExists: true }
    )
    .then(response => {
      return response.data.data;
    })
    .catch(error => Promise.reject(error));
};

export const getPublisherRevenuesCsv = (organizationId, filters) => {
  const rebuildFilters = { ...filters };
  if (filters.date) {
    rebuildFilters.from = `${formatDate(
      new Date(filters.date.getFullYear(), filters.date.getMonth(), 1),
      'api'
    )}T00:00:00`;
    rebuildFilters.to = `${formatDate(
      new Date(filters.date.getFullYear(), filters.date.getMonth() + 1, 1),
      'api'
    )}T00:00:00`;
    delete rebuildFilters.date;
  }

  if (filters.sites.length > 1500) {
    return getPublisherRevenuesCsvViaPost(organizationId, rebuildFilters);
  }

  let params = '';
  Object.keys(rebuildFilters).forEach(key => {
    if (['sites', 'currency', 'from', 'to'].includes(key)) {
      params += `${key}=${rebuildFilters[key]}&`;
    }
  });

  return api
    .get(
      `${globalReportApiUrl}/visualizations/publishers/${organizationId}/revenues/raw/csv?${params}`,
      { usePublisherTokenIfExists: true }
    )
    .then(response => {
      return response.data.data;
    })
    .catch(error => Promise.reject(error));
};

export const getTipaltiIframeUrl = (organizationId, page) => {
  return api
    .get(`${publisherApiUrlV1}/tipalti/${organizationId}?page=${page}`, true)
    .then(({ data }) => data)
    .catch(error => Promise.reject(error));
};

export const putPublisher = (id, data) =>
  api
    .put(`${umsApiUrlV1}/organizations/${id}`, data)
    .then(response => {
      return publisherAdapter(response.data.data);
    })
    .catch(error => Promise.reject(error));
