const zonePrebidConfigAdapter = z => {
  return z.config
    ? {
        ...z.config,
        ssp_conf: {
          appnexus: {
            label: 'Appnexus',
            order: 1,
            activated: z.config.ssp_conf.appnexus.activated,
          },
          smartadserver: {
            label: 'SmartAdServer',
            order: 2,
            activated: z.config.ssp_conf.smartadserver.activated,
          },
          improve: {
            label: 'Improve',
            order: 3,
            activated: z.config.ssp_conf.improve.activated,
          },
        },
      }
    : null;
};

export default zonePrebidConfigAdapter;
