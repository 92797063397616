import * as Sentry from '@sentry/browser';
import 'assets/main.scss';
import React from 'react';
import { render } from 'react-dom';
import 'react-loading-skeleton/dist/skeleton.css';
import { Provider } from 'react-redux';

import publisherStore from 'redux/publisherStore';

import ExternalRouter from 'routers/ExternalRouter';
import MaintenanceRouter from 'routers/MaintenanceRouter';

const maintenanceMode = process.env.MAINTENANCE_MODE;

Sentry.init({
  dsn: process.env.SENTRY_DNS,
  defaultIntegrations: false,
  debug: process.env.SENTRY_ENV !== 'production',
});

const Root = () => {
  return maintenanceMode === 'on' ? (
    <MaintenanceRouter />
  ) : (
    <Provider store={publisherStore}>
      <ExternalRouter />
    </Provider>
  );
};

const node = document.getElementById('root');
render(React.createElement(Root), node);
