import { api } from 'utils/utils-api';

import sellersJsonAdapter from './adapters/sellersJsonAdapter';

export const publisherApiUrlV2 = `${process.env.PUBLISHERS_API_URL}/v2`;

export const putSellersJson = (id, data) =>
  api
    .put(`${publisherApiUrlV2}/sellers/${id}`, data, {
      usePublisherTokenIfExists: true,
    })
    .then(response => {
      return sellersJsonAdapter(response.data.data);
    })
    .catch(error => Promise.reject(error));
