import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './Icon.scss';
import svgs from './svgs';

const cx = classNames.bind(styles);

const Icon = ({ name, size, className, sets, fill, onClick, noEventPropagation }) => {
  const icon = svgs[sets][name];

  const generateChildren = childs => {
    return childs.map(child => {
      const Component = child.type;
      return (
        <Component key={child.attrs.id} {...child.attrs}>
          {child.childs && generateChildren(child.childs)}
        </Component>
      );
    });
  };

  if (!icon) {
    return null;
  }

  return (
    <svg
      title={name}
      alt={name}
      className={cx('svg_icon', {
        'svg_icon--no-event': noEventPropagation,
        [className]: true,
      })}
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      fill={icon.attrs?.fill || icon.fill || fill}
      width={size}
      height={size}
      viewBox={icon.attrs?.viewBox || icon.view}
      onClick={onClick}
    >
      {icon.childs ? (
        generateChildren(icon.childs)
      ) : (
        <>
          {icon.paths ? (
            <>
              {icon.paths.map(
                ({
                  d,
                  fill: currentFill,
                  fillRule,
                  clipRule,
                  stroke,
                  strokeWidth,
                  strokeLinecap,
                  strokeLinejoin,
                }) => (
                  <path
                    className={cx('svg_icon_path')}
                    key={d.replace(/\s+/g, '')}
                    d={d}
                    clipRule={clipRule}
                    fillRule={fillRule}
                    fill={currentFill || fillRule}
                    stroke={stroke ? fill : null}
                    strokeWidth={strokeWidth}
                    strokeLinecap={strokeLinecap}
                    strokeLinejoin={strokeLinejoin}
                  />
                )
              )}
            </>
          ) : (
            <path className={cx('svg_icon_path')} d={icon.d} />
          )}
        </>
      )}
    </svg>
  );
};

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  size: PropTypes.string,
  sets: PropTypes.oneOf(['richmediaIcon', 'bootstrapIcon', 'sublimeIcon']),
  className: PropTypes.string,
  fill: PropTypes.string,
  noEventPropagation: PropTypes.bool,
  onClick: PropTypes.func,
};

Icon.defaultProps = {
  sets: 'sublimeIcon',
  size: '1em',
  className: '',
  fill: 'currentColor',
  noEventPropagation: false,
  onClick: () => {},
};

export default Icon;
