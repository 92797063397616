import { createSlice } from '@reduxjs/toolkit';

// Initial State
const initialState = {
  alerts: [],
};

/* eslint-disable no-param-reassign */
const alertSlice = createSlice({
  name: 'alert',
  initialState,
  reducers: {
    showSuccess(state, action) {
      const { message } = action.payload;
      state.alerts.push({
        id: Date.now(),
        show: true,
        type: 'success',
        message: message.toString(),
      });
    },
    showInfo(state, action) {
      const { message } = action.payload;
      state.alerts.push({
        id: Date.now(),
        show: true,
        type: 'info',
        message: message.toString(),
      });
    },
    showWarning(state, action) {
      const { message } = action.payload;
      state.alerts.push({
        id: Date.now(),
        show: true,
        type: 'warning',
        message: message.toString(),
      });
    },
    showError(state, action) {
      const { message } = action.payload;

      state.alerts.push({
        id: Date.now(),
        show: true,
        type: 'danger',
        message: Array.isArray(message) ? message.join(', ') : message.toString(),
      });
    },
    clearAlert(state, action) {
      const { id } = action.payload;
      state.alerts = state.alerts.filter(a => a.id !== id);
    },
    clearAlerts(state) {
      state.alerts = [];
    },
  },
});
/* eslint-enable no-param-reassign */

export const { showSuccess, showInfo, showWarning, showError, clearAlert, clearAlerts } =
  alertSlice.actions;

// Selectors
export const selectAlerts = state => state.alert.alerts;

const alertReducer = alertSlice.reducer;
export default alertReducer;
