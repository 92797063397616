/* eslint-disable no-script-url */
// need for : after javascript for actions logs view
/**
 * Global constants
 */

export const applicationColors = {
  approved1: '#13da9e',
  approved2: '#16c58e',
  error: '#ed0505',
  warning1: '#ffcc42',
  warning2: '#ffa700',
  ready1: '#50c6e8',
  ready2: '#3cb4ff',
  inactive: '#cbd4e1',
};

export const languageflags = {
  ca: 'es-ct',
  en: 'gb',
  uk: 'gb',
  zh: 'cn',
  ja: 'jp',
  tl: 'ph',
  ms: 'my',
};

export const languages = [
  {
    value: 'ca',
    label: 'Catalonian',
  },
  {
    value: 'zh',
    label: 'Chinese',
  },
  {
    value: 'nl',
    label: 'Dutch',
  },
  {
    value: 'en',
    label: 'English',
  },
  {
    value: 'es',
    label: 'Español',
  },
  {
    value: 'fr',
    label: 'Français',
  },
  {
    value: 'de',
    label: 'German',
  },
  {
    value: 'id',
    label: 'Indonesian',
  },
  {
    value: 'it',
    label: 'Italian',
  },
  {
    value: 'ja',
    label: 'Japanese',
  },
  {
    value: 'ms',
    label: 'Malay',
  },
  {
    value: 'pl',
    label: 'Polish',
  },
  {
    value: 'pt',
    label: 'Portuguese',
  },
  {
    value: 'tl',
    label: 'Tagalog-filipino',
  },
  {
    value: 'th',
    label: 'Thai',
  },
  {
    value: 'tr',
    label: 'Turkish',
  },
  {
    value: 'vi',
    label: 'Vietnamese',
  },
];

export const isoCodes = [
  'world',
  'ac',
  'ad',
  'ae',
  'af',
  'ag',
  'ai',
  'al',
  'am',
  'ao',
  'aq',
  'ar',
  'as',
  'at',
  'au',
  'aw',
  'ax',
  'az',
  'ba',
  'bb',
  'bd',
  'be',
  'bf',
  'bg',
  'bh',
  'bi',
  'bj',
  'bl',
  'bm',
  'bn',
  'bo',
  'bq',
  'br',
  'bs',
  'bt',
  'bv',
  'bw',
  'by',
  'bz',
  'ca',
  'cc',
  'cd',
  'cf',
  'cg',
  'ch',
  'ci',
  'ck',
  'cl',
  'cm',
  'cn',
  'co',
  'cp',
  'cr',
  'cu',
  'cv',
  'cw',
  'cx',
  'cy',
  'cz',
  'de',
  'dg',
  'dj',
  'dk',
  'dm',
  'do',
  'dz',
  'ea',
  'ec',
  'ee',
  'eg',
  'eh',
  'er',
  'es-ct',
  'es-ga',
  'es',
  'et',
  'eu',
  'fi',
  'fj',
  'fk',
  'fm',
  'fo',
  'fr',
  'ga',
  'gb-eng',
  'gb-nir',
  'gb-sct',
  'gb-wls',
  'gb',
  'gd',
  'ge',
  'gf',
  'gg',
  'gh',
  'gi',
  'gl',
  'gm',
  'gn',
  'gp',
  'gq',
  'gr',
  'gs',
  'gt',
  'gu',
  'gw',
  'gy',
  'hk',
  'hm',
  'hn',
  'hr',
  'ht',
  'hu',
  'ic',
  'id',
  'ie',
  'il',
  'im',
  'in',
  'io',
  'iq',
  'ir',
  'is',
  'it',
  'je',
  'jm',
  'jo',
  'jp',
  'ke',
  'kg',
  'kh',
  'ki',
  'km',
  'kn',
  'kp',
  'kr',
  'kw',
  'ky',
  'kz',
  'la',
  'lb',
  'lc',
  'li',
  'lk',
  'lr',
  'ls',
  'lt',
  'lu',
  'lv',
  'ly',
  'ma',
  'mc',
  'md',
  'me',
  'mf',
  'mg',
  'mh',
  'mk',
  'ml',
  'mm',
  'mn',
  'mo',
  'mp',
  'mq',
  'mr',
  'ms',
  'mt',
  'mu',
  'mv',
  'mw',
  'mx',
  'my',
  'mz',
  'na',
  'nc',
  'ne',
  'nf',
  'ng',
  'ni',
  'nl',
  'no',
  'np',
  'nr',
  'nu',
  'nz',
  'om',
  'pa',
  'pe',
  'pf',
  'pg',
  'ph',
  'pk',
  'pl',
  'pm',
  'pn',
  'pr',
  'ps',
  'pt',
  'pw',
  'py',
  'qa',
  're',
  'ro',
  'rs',
  'ru',
  'rw',
  'sa',
  'sb',
  'sc',
  'sd',
  'se',
  'sg',
  'sh',
  'si',
  'sj',
  'sk',
  'sl',
  'sm',
  'sn',
  'so',
  'sr',
  'ss',
  'st',
  'sv',
  'sx',
  'sy',
  'sz',
  'ta',
  'tc',
  'td',
  'tf',
  'tg',
  'th',
  'tj',
  'tk',
  'tl',
  'tm',
  'tn',
  'to',
  'tr',
  'tt',
  'tv',
  'tw',
  'tz',
  'ua',
  'ug',
  'um',
  'un',
  'us',
  'uy',
  'uz',
  'va',
  'vc',
  've',
  'vg',
  'vi',
  'vn',
  'vu',
  'wf',
  'ws',
  'xk',
  'xx',
  'ye',
  'yt',
  'za',
  'zm',
  'zw',
  '_I',
];

export const zoneFormats = [
  {
    label: 'Skinz',
    value: 1,
  },
  {
    label: 'Rise / Cut out / Others',
    value: 0,
  },
];

export const formats = [
  {
    value: 'skinz',
    label: 'Skinz',
  },
  {
    value: 'video',
    label: 'Video',
  },
  {
    value: 'mobile',
    label: 'Mobile',
  },
];

export const broadcastingTypes = [
  {
    value: 'longtail',
    label: 'Long tail',
  },
  {
    value: 'standard',
    label: 'Standard',
  },
  {
    value: 'premium',
    label: 'Premium',
  },
];

export const trafficTypes = [
  {
    value: 'rtb',
    label: 'RTB',
  },
  {
    value: 'pmp',
    label: 'PMP',
  },
  {
    value: 'adserving',
    label: 'Adserving',
  },
  {
    value: 'redirect',
    label: 'Redirect',
  },
  {
    value: 'test',
    label: 'Test',
  },
];

export const paramsSearchCampaignToCreative = {
  creative_type: 'creative_type',
  creative_source: 'creative_source',
  ad_template: 'ad_template',
  ad_id: 'search',
  search: 'searchCampaign',
};
export const ssps = [
  {
    value: 'appnexus',
    label: 'Xandr (Appnexus)',
  },
  {
    value: 'criteo',
    label: 'Criteo',
  },
  {
    value: 'criteovideo',
    label: 'Criteo video',
  },
  {
    value: 'improve',
    label: 'Improve',
  },
  {
    value: 'improve-gvast',
    label: 'Improve GVAST',
  },
  {
    value: 'rubicon',
    label: 'Rubicon',
  },
  {
    value: 'smartadserver',
    label: 'SmartAdServer',
  },
];

export const sspForSitelist = [
  {
    value: 'improve',
    label: 'Improve',
  },
  {
    value: 'appnexus',
    label: 'Xandr (Appnexus)',
  },
  {
    value: 'smartadserver',
    label: 'Equativ (SmartAdServer)',
  },
];

export const sspsForTable = [
  ...ssps,
  {
    value: 'sticky',
    label: 'Sticky',
  },
];

export const campaignType = [
  {
    value: 'deal',
    label: 'Programmatic Deal',
  },
  {
    value: 'direct',
    label: 'Direct IO',
  },
];

export const cappingDurations = [
  {
    label: '24 hours',
    value: 86400,
  },
  {
    label: '12 hours',
    value: 43200,
  },
  {
    label: '6 hours',
    value: 21600,
  },
  {
    label: '1 hour',
    value: 3600,
  },
];

export const buyers = [
  {
    label: 'Sublime',
    value: 'sublime',
  },
  {
    label: 'Azerion',
    value: 'azerion',
  },
  {
    label: 'Other',
    value: 'other',
  },
];

export const allowedCampaigns = ['redirect', 'test', 'adserving'];

export const allowedRedirectCampaigns = ['redirect', 'test'];

export const passbackRenders = [
  {
    label: 'Iframe',
    value: 'iframe',
  },
  {
    label: 'Resized iframe',
    value: 'iframeResized',
  },
  {
    label: 'Resize iframe and parent iframe',
    value: 'iframeResizeParent',
  },
  {
    label: 'Pull to top',
    value: 'pullToTop',
  },
];

export const possibleAppendPositions = [
  {
    label: 'After the element',
    value: 0,
  },
  {
    label: 'Inside the element',
    value: 1,
  },
];

export const moatVideoViewabilities = [
  {
    label: 'Perform video player viewability',
    value: 2,
  },
  {
    label: 'Perform with display and video player viewability',
    value: 3,
  },
];

export const moatDisplayViewabilities = [
  {
    label: 'Disabled',
    value: 0,
  },
  {
    label: 'Perform display viewability',
    value: 1,
  },
];

export const bgVideoOptions = [
  {
    label: 'No video',
    value: 'none',
  },
  {
    label: 'Same video (blurred)',
    value: 'same',
  },
  {
    label: 'Different video',
    value: 'different',
  },
];

export const acceptedPlacementTypes = [
  {
    label: 'Desktop Skin',
    value: 'skinz',
  },
  {
    label: 'Mobile Mskin',
    value: 'mskinz',
  },
  {
    label: 'Mobile TopRoll',
    value: 'toproll',
  },
  {
    label: 'Mobile PageSkin',
    value: 'pageskin',
  },
  {
    label: 'Mobile Bottom',
    value: 'bottom',
  },
];

export const acceptedCampaignTrafficTypes = [
  {
    label: 'Direct',
    value: 'direct',
  },
  ...trafficTypes.filter(t => t.value !== 'redirect' && t.value !== 'test'),
];

export const acceptedAdTypes = [
  ...formats,
  {
    label: 'Reminder',
    value: 'reminder',
  },
];

export const tagTypes = [
  {
    label: 'Waterfall',
    value: 'waterfall',
  },
  {
    label: 'Prebid',
    value: 'prebid',
  },
  {
    label: 'Infeed',
    value: 'infeed',
  },
  {
    label: 'In-Video',
    value: 'invideo',
  },
];

export const campaignStatuses = ['paused', 'live'];
export const zoneMobileStatuses = [
  {
    value: 'pending',
    label: 'Pending',
  },
  {
    value: 'excluded',
    label: 'Excluded from zones export',
  },
  {
    value: 'adserving',
    label: 'Adserving',
  },
  {
    value: 'pmp',
    label: 'PMP',
  },
  {
    value: 'css',
    label: 'CSS pending',
  },
  {
    value: 'publisher',
    label: 'Publisher pending',
  },
  {
    value: 'rtb',
    label: 'RTB pending',
  },
  {
    value: 'sales',
    label: 'Sales pending',
  },
  {
    value: 'removed',
    label: 'Tag removed',
  },
  {
    value: 'awaitingcampaign',
    label: 'Awaiting Campaign',
  },
  {
    value: 'ready',
    label: 'Ready',
  },
  {
    value: 'other',
    label: 'Other',
  },
];
export const zoneDesktopStatuses = [
  {
    value: 'pending',
    label: 'Pending',
  },
  {
    value: 'excluded',
    label: 'Excluded from zones export',
  },
  {
    value: 'adserving',
    label: 'Adserving',
  },
  {
    value: 'pmp',
    label: 'PMP',
  },
  {
    value: 'css',
    label: 'CSS pending',
  },
  {
    value: 'publisher',
    label: 'Publisher pending',
  },
  {
    value: 'rtb',
    label: 'RTB pending',
  },
  {
    value: 'sales',
    label: 'Sales pending',
  },
  {
    value: 'removed',
    label: 'Tag removed',
  },
  {
    value: 'awaitingcampaign',
    label: 'Awaiting Campaign',
  },
  {
    value: 'ready',
    label: 'Ready',
  },
  {
    value: 'other',
    label: 'Other',
  },
];

export const organizationTypes = [
  {
    value: 'sales_house',
    label: 'Sales house',
  },
  {
    value: 'publisher',
    label: 'Publisher',
  },
];

export const pagesViewsPossibilities = [
  {
    value: '0-50k',
    label: '0-50k',
  },
  {
    value: '50k-500k',
    label: '50k-500k',
  },
  {
    value: '500k-5M',
    label: '500k-5M',
  },
  {
    value: 'above 5M',
    label: '5M and more',
  },
];

export const connectorPriorityOptions = [
  {
    label: 'Priority 1',
    value: 1,
  },
  {
    label: 'Priority 2',
    value: 2,
  },
  {
    label: 'Priority 3',
    value: 3,
  },
  {
    label: 'Priority 4',
    value: 4,
  },
  {
    label: 'Priority 5',
    value: 5,
  },
];

export const standalonePublisherId = parseInt(process.env.STANDALONE_PUBLISHER_ID, 10);

export const sitelistAdvertiserUrl = `${process.env.SALES_URL}/sitelists/advertiser/`;

export const publisherZoneUrl = `${process.env.PUBOPS_URL}/zones/`;

export const cdnUrl = process.env.CDN_URL;
export const ayadsUrl = process.env.AYADS_URL;
export const sacUrl = process.env.SAC_API_URL;
export const sublimeUrl = process.env.BO_SUBLIME_URL;
export const campaignUrl = `${ayadsUrl}/campaigns.php`;
export const connectorUrl = `${ayadsUrl}/connectors.php`;
export const adsUrl = `${ayadsUrl}/ads.php`;
export const siteUrl = `${ayadsUrl}/sites.php`;
export const zoneUrl = `${ayadsUrl}/zones.php`;

export const redirectStatsLookerUrl = `${process.env.LOOKER_URL}/looks/3027?toggle=det&f`;
export const pmpAdServingStatsLookerUrl = `${process.env.LOOKER_URL}/looks/3028?toggle=det&f`;
export const connectorsGroupsStatsLookerUrl = `${process.env.LOOKER_URL}/looks/4680?toggle=det&f`;
export const zonesStatsLookerUrl = `${process.env.LOOKER_URL}/looks/5025?toggle=det&f[metadata_zones.id]=`;

export const creativeMobilePreview = `${process.env.PREVIEW_URL}/mobile-mskinz.html#ayads-aid=`;
export const creativeDesktopPreview = `${process.env.PREVIEW_URL}/desktop.html#ayads-aid=`;
export const creativeDesktopTabletPreview = `${process.env.PREVIEW_URL}/desktop-tablet.html#ayads-aid=`;
export const creativeTabletPreview = `${process.env.PREVIEW_URL}/tablet.html#ayads-aid=`;

export const adminNavItems = [
  {
    id: 'admin_menu_1',
    type: 'item',
    title: 'Users',
    link: '/admin/accounts',
    icon: 'userGroup',
    iconSets: 'richmediaIcon',
  },
  {
    id: 'admin_menu_2',
    type: 'item',
    title: 'Invoices',
    link: '/admin/invoices',
    icon: 'paymentCard',
    iconSets: 'richmediaIcon',
  },
  {
    id: 'admin_menu_3',
    type: 'item',
    title: 'Privacy',
    externalLink: sublimeUrl,
    icon: 'safetyCertificate',
    iconSets: 'richmediaIcon',
  },
];

export const brandsNavItems = [
  {
    id: 'brands_menu_1',
    type: 'item',
    title: 'Imported Brands',
    link: '/brands/imported-brands',
    icon: 'download',
    iconSets: 'richmediaIcon',
  },
  {
    id: 'brands_menu_2',
    type: 'item',
    title: 'Sublime Brands',
    link: '/brands/sublime-brands',
    icon: 'sublime',
    iconSets: 'richmediaIcon',
  },
  {
    id: 'brands_menu_3',
    type: 'item',
    title: 'Advertisers',
    link: '/brands/advertisers',
    icon: 'ads',
    iconSets: 'richmediaIcon',
  },
  {
    id: 'brands_menu_4',
    type: 'item',
    title: 'Categories',
    link: '/brands/categories',
    icon: 'folderOpen',
    iconSets: 'richmediaIcon',
  },
];

export const supplyNavItems = [
  {
    id: 'pubops_menu_1',
    type: 'item',
    title: 'Leads',
    link: '/pubops/leads',
    icon: 'trophy',
    iconSets: 'richmediaIcon',
  },
  {
    id: 'pubops_menu_2',
    type: 'item',
    title: 'All publishers',
    link: '/pubops/publishers',
    icon: 'fileUser',
    iconSets: 'richmediaIcon',
  },
  {
    id: 'pubops_menu_3',
    type: 'item',
    title: 'Inventory',
    icon: 'project',
    iconSets: 'richmediaIcon',
    items: [
      {
        id: 'pubops_sub_menu_1',
        type: 'item',
        title: 'Administration',
        link: '/pubops/inventory',
      },
      {
        id: 'pubops_sub_menu_2',
        type: 'item',
        title: 'Sites',
        link: '/pubops/sites',
      },
      {
        id: 'pubops_sub_menu_4',
        type: 'item',
        title: 'OneSkin Sitelist',
        link: '/pubops/oneskin-sitelist',
      },
      {
        id: 'pubops_sub_menu_5',
        type: 'item',
        title: 'Connector Groups',
        link: '/pubops/connectors-groups',
      },
    ],
  },
];

export const restrictedDemandNavItem = [
  {
    id: 'adops_menu_1',
    type: 'item',
    icon: 'library',
    iconSets: 'richmediaIcon',
    title: 'Campaigns',
    link: '/campaigns',
  },
];

export const demandNavItem = [
  ...restrictedDemandNavItem,
  {
    id: 'adops_menu_2',
    type: 'item',
    icon: 'showcase',
    iconSets: 'richmediaIcon',
    title: 'Interactive Showcase',
    link: '/interactive-showcase',
  },
  {
    id: 'adops_menu_3',
    type: 'item',
    icon: 'export',
    iconSets: 'richmediaIcon',
    title: 'Commercial Export',
    link: '/commercial-export-csv',
  },
  {
    id: 'adops_menu_4',
    type: 'item',
    icon: 'upload',
    iconSets: 'richmediaIcon',
    title: 'Site List SSP Upload',
    link: '/sitelist-ssp-upload',
  },
  {
    id: 'adops_menu_5',
    type: 'item',
    icon: 'fileDone',
    iconSets: 'richmediaIcon',
    title: 'Post Campaign Report',
    link: '/post-campaign-report',
  },
];

export const salesNavItem = [
  {
    id: 'sales_menu_1',
    type: 'item',
    icon: 'profile',
    iconSets: 'richmediaIcon',
    title: 'Site List Generator',
    link: '/sales/sitelists',
    externalLink: `${window.location.origin}/sales/sitelists`,
  },
];

export const publisherNavItems = [
  {
    id: 'publishers_menu_1',
    type: 'item',
    title: 'Dashboard',
    link: '/publishers',
    icon: 'dashboard',
    iconSets: 'richmediaIcon',
  },
  {
    id: 'publishers_menu_2',
    type: 'item',
    title: 'Settings',
    icon: 'control',
    iconSets: 'richmediaIcon',
    items: [
      {
        id: 'publishers_menu_1_sub_menu_1',
        type: 'item',
        title: 'Sellers.json setup',
        link: '/publishers/configs/sellers-json-setup',
      },
    ],
  },
  {
    id: 'publishers_menu_3',
    type: 'item',
    title: 'Payment',
    icon: 'paymentCard',
    iconSets: 'richmediaIcon',
    items: [
      {
        id: 'publishers_menu_2_sub_menu_1',
        type: 'item',
        title: 'Payment setup',
        link: '/publishers/payment/setup',
      },
      {
        id: 'publishers_menu_2_sub_menu_2',
        type: 'item',
        title: 'Invoices history',
        link: '/publishers/payment/history',
      },
      {
        id: 'publishers_menu_2_sub_menu_3',
        type: 'item',
        title: 'Payments history',
        link: '/publishers/payment/payment-history',
      },
    ],
  },
  {
    id: 'publishers_menu_4',
    type: 'item',
    title: 'Inventory',
    icon: 'project',
    iconSets: 'richmediaIcon',
    items: [
      {
        id: 'publishers_menu_3_sub_menu_1',
        type: 'item',
        title: 'Sites',
        link: '/publishers/inventory/sites',
      },
      {
        id: 'publishers_menu_3_sub_menu_2',
        type: 'item',
        title: 'Zones',
        link: '/publishers/inventory/zones',
      },
    ],
  },
];

export const adminHomePageNavItems = {
  Admin: [
    {
      id: 'admin_menu_1',
      type: 'item',
      title: 'Users',
      link: '/admin/accounts',
      icon: 'userGroup',
      iconSets: 'richmediaIcon',
    },
    {
      id: 'admin_menu_2',
      type: 'item',
      title: 'Invoices',
      link: '/admin/invoices',
      icon: 'paymentCard',
      iconSets: 'richmediaIcon',
    },
    {
      id: 'admin_menu_3',
      type: 'item',
      title: 'Privacy',
      externalLink: sublimeUrl,
      icon: 'safetyCertificate',
      iconSets: 'richmediaIcon',
    },
  ],
};

export const supplyHomePageNavItems = {
  Supply: [
    {
      id: 'pubops_menu_1',
      type: 'item',
      title: 'Leads',
      link: '/pubops/leads',
      icon: 'trophy',
      iconSets: 'richmediaIcon',
    },
    {
      id: 'pubops_menu_2',
      type: 'item',
      title: 'All publishers',
      link: '/pubops/publishers',
      icon: 'fileUser',
      iconSets: 'richmediaIcon',
    },
    {
      id: 'pubops_menu_3',
      type: 'item',
      title: 'Inventory',
      icon: 'project',
      iconSets: 'richmediaIcon',
      items: [
        {
          id: 'pubops_sub_menu_1',
          type: 'item',
          title: 'Administration',
          link: '/pubops/inventory',
        },
        {
          id: 'pubops_sub_menu_2',
          type: 'item',
          title: 'Sites',
          link: '/pubops/sites',
        },
        {
          id: 'pubops_sub_menu_4',
          type: 'item',
          title: 'OneSkin Sitelist',
          link: '/pubops/oneskin-sitelist',
        },
        {
          id: 'pubops_sub_menu_5',
          type: 'item',
          title: 'Connector Groups',
          link: '/pubops/connectors-groups',
        },
      ],
    },
  ],
};

export const demandHomePageNavItems = {
  Demand: [
    {
      id: 'adops_menu_1',
      type: 'item',
      icon: 'library',
      iconSets: 'richmediaIcon',
      title: 'Campaigns',
      link: '/campaigns',
      roles: ['creative_manager', 'creative_manager_restricted'],
    },
    {
      id: 'adops_menu_2',
      type: 'item',
      icon: 'showcase',
      iconSets: 'richmediaIcon',
      title: 'Interactive Showcase',
      link: '/interactive-showcase',
      roles: ['creative_manager'],
    },
    {
      id: 'adops_menu_3',
      type: 'item',
      icon: 'export',
      iconSets: 'richmediaIcon',
      title: 'Commercial Export',
      link: '/commercial-export-csv',
      roles: ['creative_manager'],
    },
    {
      id: 'adops_menu_4',
      type: 'item',
      icon: 'upload',
      iconSets: 'richmediaIcon',
      title: 'Site List SSP Upload',
      link: '/sitelist-ssp-upload',
      roles: ['creative_manager'],
    },
    {
      id: 'adops_menu_5',
      type: 'item',
      icon: 'fileDone',
      iconSets: 'richmediaIcon',
      title: 'Post Campaign Report',
      link: '/post-campaign-report',
      roles: ['creative_manager'],
    },
    {
      id: 'adops_menu_6',
      type: 'item',
      icon: 'profile',
      iconSets: 'richmediaIcon',
      title: 'Site List Generator',
      link: '/sales/sitelists',
      externalLink: `${window.location.origin}/sales/sitelists`,
      roles: ['internal'],
    },
    {
      id: 'adops_menu_7',
      type: 'item',
      title: 'Brands Manager',
      icon: 'sublime',
      iconSets: 'richmediaIcon',
      roles: ['internal'],
      items: [
        {
          id: 'adops_sub_menu_1',
          type: 'item',
          title: 'Imported Brands',
          link: '/brands/imported-brands',
        },
        {
          id: 'adops_sub_menu_2',
          type: 'item',
          title: 'Sublime Brands',
          link: '/brands/sublime-brands',
        },
        {
          id: 'adops_sub_menu_3',
          type: 'item',
          title: 'Advertisers',
          link: '/brands/advertisers',
        },
        {
          id: 'adops_sub_menu_4',
          type: 'item',
          title: 'Categories',
          link: '/brands/categories',
        },
      ],
    },
  ],
};

export const LegalsNavItemsFr = [
  {
    id: 'legals_menu_1',
    type: 'item',
    title: 'GDPR',
    link: '/privacy/fr',
    icon: 'verification',
  },
  {
    id: 'legals_menu_2',
    type: 'item',
    title: 'Legal Mentions',
    link: '/legal-mentions/fr',
    icon: 'file-protect',
  },
  {
    id: 'legals_menu_3',
    type: 'item',
    title: 'Cookies Policy',
    link: '/cookies-policy/fr',
    icon: 'cookie',
  },
  {
    id: 'legals_menu_4',
    type: 'item',
    title: 'Optout',
    link: '/optout',
    icon: 'optout',
  },
  {
    id: 'legals_menu_5',
    type: 'item',
    title: 'Intellectual properties',
    link: '/intellectual-properties',
    icon: 'idea',
  },
];

export const LegalsNavItemsEn = [
  {
    id: 'legals_menu_1',
    type: 'item',
    title: 'GDPR',
    link: '/privacy/en',
    icon: 'verification',
  },
  {
    id: 'legals_menu_2',
    type: 'item',
    title: 'Legal Mentions',
    link: '/legal-mentions/en',
    icon: 'file-protect',
  },
  {
    id: 'legals_menu_3',
    type: 'item',
    title: 'Cookies Policy',
    link: '/cookies-policy/en',
    icon: 'cookie',
  },
  {
    id: 'legals_menu_4',
    type: 'item',
    title: 'Optout',
    link: '/optout',
    icon: 'optout',
  },
  {
    id: 'legals_menu_5',
    type: 'item',
    title: 'Intellectual properties',
    link: '/intellectual-properties',
    icon: 'idea',
  },
];

export const identifierType = [
  {
    value: 'hash',
    label: 'Zones Hashs',
  },
  {
    value: 'id',
    label: 'Zones ID',
  },
];

export const keysType = [
  {
    value: 'zid',
    label: 'Zones IDs',
  },
  {
    value: 'hash',
    label: 'Zones Hashs',
  },
  {
    value: 'placement_id',
    label: 'Placements IDs',
  },
];

export const monetizationType = [
  {
    value: '0',
    label: 'i0',
  },
  {
    value: '3',
    label: 'i3',
  },
  {
    value: '5',
    label: 'i5',
  },
];

export const seat = [
  {
    value: 'sas',
    label: 'SAS (FR)',
  },
  {
    value: 'ltd',
    label: 'LTD (UK)',
  },
  {
    value: 'inc',
    label: 'INC (US)',
  },
];

export const headerBiddingSpps = {
  appnexus: {
    order: 1,
    label: 'Appnexus',
    activated: false,
  },
  smartadserver: {
    order: 2,
    label: 'SmartAdServer',
    activated: false,
  },
  improve: {
    order: 3,
    label: 'Improve',
    activated: false,
  },
};

export const showcaseFormats = [
  {
    value: '*',
    label: 'All Formats',
  },
  {
    value: 'Classic Plus',
    label: 'Classic Plus',
  },
  {
    value: 'Tailor Made',
    label: 'Tailor Made',
  },
];

export const showcaseFeatures = [
  {
    value: '*',
    label: 'All Features',
  },
  {
    value: '360 Image',
    label: '360 Image',
  },
  {
    value: '360 Video',
    label: '360 Video',
  },
  {
    value: 'Animation',
    label: 'Animation',
  },
  {
    value: 'Configurator',
    label: 'Configurator',
  },
  {
    value: 'Countdown',
    label: 'Countdown',
  },
  {
    value: 'DCO',
    label: 'DCO',
  },
  {
    value: 'Drag & Drop',
    label: 'Drag & Drop',
  },
  {
    value: 'Form Fill',
    label: 'Form Fill',
  },
  {
    value: 'Gaming',
    label: 'Gaming',
  },
  {
    value: 'Interactive Video',
    label: 'Interactive Video',
  },
  {
    value: 'Sliding',
    label: 'Sliding',
  },
  {
    value: 'Store Locator',
    label: 'Store Locator',
  },
  {
    value: 'Swapping',
    label: 'Swapping',
  },
  {
    value: 'Video Expand',
    label: 'Video Expand',
  },
];

export const showcaseCountries = [
  {
    value: '*',
    label: 'All Countries',
  },
  {
    value: 'France',
    label: 'France',
  },
  {
    value: 'United-Kingdom',
    label: 'United-Kingdom',
  },
  {
    value: 'Singapore',
    label: 'Singapore',
  },
  {
    value: 'Italy',
    label: 'Italy',
  },
  {
    value: 'Spain',
    label: 'Spain',
  },
  {
    value: 'Germany',
    label: 'Germany',
  },
  {
    value: 'Mexico',
    label: 'Mexico',
  },
  {
    value: 'United-States',
    label: 'United-States',
  },
  {
    value: 'Australia',
    label: 'Australia',
  },
];

export const showcaseIndustries = [
  {
    value: '*',
    label: 'All Industries',
  },
  {
    value: 'Beauty',
    label: 'Beauty',
  },
  {
    value: 'Automotive',
    label: 'Automotive',
  },
  {
    value: 'Entertainment',
    label: 'Entertainment',
  },
  {
    value: 'Finance',
    label: 'Finance',
  },
  {
    value: 'Food',
    label: 'Food',
  },
  {
    value: 'Health',
    label: 'Health',
  },
  {
    value: 'High-Tech',
    label: 'High-Tech',
  },
  {
    value: 'Luxury',
    label: 'Luxury',
  },
  {
    value: 'Retail',
    label: 'Retail',
  },
  {
    value: 'Travel',
    label: 'Travel',
  },
];

export const clickableStatesDisplayExpand = [
  {
    label: 'Do nothing',
    value: 'prevent_default_behavior', // false
    infoBox: 'Used to allow interactivity',
  },
  {
    label: 'Redirect to clickUrl',
    value: 'redirect_url', // true
  },
  {
    label: 'Redirect to another Url',
    value: 'custom_redirect_url', // Custom URL
  },
];

export const clickableStatesDisplayFullSticky = [
  {
    label: 'Expand',
    value: 'default_behavior', // omitted
  },
  ...clickableStatesDisplayExpand,
];

export const clickableStatesVideo = [
  {
    label: 'Expand & mute/unmute video',
    value: 'default_behavior', // omitted
  },
  ...clickableStatesDisplayExpand,
];

export const zonePublisherCreationTabs = [
  {
    label: 'zone settings',
    id: 'settings',
    icon: {
      sets: 'richmediaIcon',
      name: 'control',
    },
  },
];

export const zonePublisherEditionTabs = [
  ...zonePublisherCreationTabs,
  {
    label: 'tag setup',
    id: 'tagSetup',
    icon: {
      sets: 'richmediaIcon',
      name: 'code',
    },
  },
];

export const zoneCreationTabs = [
  {
    label: 'zone settings',
    id: 'settings',
    icon: {
      sets: 'richmediaIcon',
      name: 'control',
    },
  },
  {
    label: 'integration',
    id: 'integration',
    icon: {
      sets: 'richmediaIcon',
      name: 'code',
    },
  },
];

export const zoneEditionTabs = [
  ...zoneCreationTabs,
  {
    label: 'connectors',
    id: 'connectors',
    icon: {
      sets: 'richmediaIcon',
      name: 'api',
    },
  },
  {
    label: 'adserving',
    id: 'adserving',
    icon: {
      sets: 'richmediaIcon',
      name: 'api',
    },
  },
  {
    label: 'tag setup',
    id: 'tagSetup',
    icon: {
      sets: 'richmediaIcon',
      name: 'code',
    },
  },
  {
    label: 'header bidding',
    id: 'header-bidding',
    icon: {
      sets: 'bootstrapIcon',
      name: 'auction',
    },
  },
  {
    label: 'buy card',
    id: 'buycard',
    icon: {
      sets: 'bootstrapIcon',
      name: 'currencies',
    },
  },
  {
    label: 'oneskin setting',
    id: 'oneskin-setting',
    icon: {
      sets: 'bootstrapIcon',
      name: 'command',
    },
  },
];

export const campaignAttributeNames = {
  name: {
    name: 'Campaign name',
    section: 'Campaign Setup',
    type: 'string',
  },
  traffic_type: {
    name: 'Traffic type',
    section: 'Campaign Setup',
    type: 'select',
    possibilities: trafficTypes,
  },
  buyer: {
    name: 'Buyer',
    section: 'Campaign Setup',
    type: 'select',
    possibilities: buyers,
  },
  lang: {
    name: 'Language',
    section: 'Campaign Setup',
    type: 'select',
    possibilities: languages,
  },
  country: {
    name: 'Country',
    section: 'Campaign Setup',
    type: 'string',
  },
  advertiser_name: {
    name: 'Advertiser',
    section: 'Campaign Setup',
    type: 'string',
  },
  industry_name: {
    name: 'Industry',
    section: 'Campaign Setup',
    type: 'string',
  },
  status: {
    name: 'Status',
    section: 'Campaign Setup',
    type: 'select',
    possibilities: campaignStatuses,
  },
  begin: {
    name: 'Start date',
    section: 'Campaign Setup',
    type: 'date',
  },
  end: {
    name: 'End date',
    section: 'Campaign Setup',
    type: 'date',
  },
  ssp_name: {
    name: 'SSP name',
    section: 'Campaign Setup',
    type: 'select',
    possibilities: ssps,
  },
};

export const zoneAttributeNames = {
  name: {
    name: 'Zone name',
    section: 'Zone Setup',
    type: 'string',
  },
  site_id: {
    name: 'Site',
    section: 'Zone Setup',
    type: 'string',
  },
  url: {
    name: 'url',
    section: 'Zone Setup',
    type: 'string',
  },
  tag_type: {
    name: 'Tag type',
    section: 'Zone Setup',
    type: 'string',
  },
  description: {
    name: 'Local description',
    section: 'Zone Setup',
    type: 'string',
  },
  description_en: {
    name: 'International description',
    section: 'Zone Setup',
    type: 'string',
  },
  status: {
    name: 'Desktop Monetization Status',
    section: 'Zone Setup',
    type: 'select',
    possibilities: zoneDesktopStatuses,
  },
  desktop_monetized_on: {
    name: 'Desktop Monetization',
    section: 'Zone Setup',
    type: 'select',
    possibilities: monetizationType,
  },
  status_mobile: {
    name: 'Mobile Monetization Status',
    section: 'Zone Setup',
    type: 'select',
    possibilities: zoneMobileStatuses,
  },
  mobile_monetized_on: {
    name: 'Mobile Monetization',
    section: 'Zone Setup',
    type: 'select',
    possibilities: monetizationType,
  },
  main_category_name: {
    name: 'Main channel',
    section: 'Zone Setup',
    type: 'string',
  },
  sub_category_name: {
    name: 'Sub channel',
    section: 'Zone Setup',
    type: 'string',
  },
  iab_category_names: {
    name: 'IAB Categories',
    section: 'Zone Setup',
    type: 'simple-compared-list',
  },
  accepted_placement_types: {
    name: 'Accepted placement types',
    section: 'Zone Setup',
    type: 'simple-compared-list',
  },
  excluded_campaign_traffic_types: {
    name: 'Non accepted campaign traffic types',
    section: 'Zone Setup',
    type: 'simple-compared-list',
  },
  excluded_ad_types: {
    name: 'Non accepted ad types',
    section: 'Zone Setup',
    type: 'simple-compared-list',
  },
  video_mute: {
    name: 'Mute button',
    section: 'Zone Setup',
    type: 'boolean',
  },
  video_autoplay: {
    name: 'AutoPlay video',
    section: 'Zone Setup',
    type: 'boolean',
  },
  passback: {
    name: 'Desktop passback',
    section: 'Integration',
    sectionIcon: 'code',
    type: 'code',
  },
  passback_render: {
    name: 'Desktop passback render',
    section: 'Integration',
    sectionIcon: 'code',
    type: 'select',
    possibilities: passbackRenders,
  },
  mobilepassback: {
    name: 'Non Desktop passback',
    section: 'Integration',
    sectionIcon: 'code',
    type: 'code',
  },
  'mobile_setup.footer_fixed': {
    name: 'Sticky top & bottom',
    section: 'Integration',
    sectionIcon: 'code',
    type: 'boolean',
  },
  mobile_bottom_js: {
    name: 'Javascript: Mobile Bottom',
    section: 'Integration',
    sectionIcon: 'code',
    type: 'code',
  },
  mobile_bottom_css: {
    name: 'Css: Mobile Bottom',
    section: 'Integration',
    sectionIcon: 'code',
    type: 'code',
  },
  cmp_consent_timeout: {
    name: 'CMP consent timeout',
    section: 'Integration',
    sectionIcon: 'code',
    type: 'string',
  },
  cmp_wait_timeout: {
    name: 'CMP wait timeout',
    section: 'Integration',
    sectionIcon: 'code',
    type: 'string',
  },
  use_cls_desktop: {
    name: 'Use CLS Desktop',
    section: 'Integration',
    sectionIcon: 'code',
    type: 'boolean',
  },
  desktop_status: {
    name: 'Desktop OneSkin Ready',
    section: 'Integration',
    sectionIcon: 'code',
    type: 'boolean',
  },
  desktop_html: {
    name: 'Desktop HTML',
    section: 'Integration',
    sectionIcon: 'code',
    type: 'code',
  },
  desktop_html_cls: {
    name: 'Desktop HTML (CLS)',
    section: 'Integration',
    sectionIcon: 'code',
    type: 'code',
  },
  desktop_scale: {
    name: 'Desktop Resize skin',
    section: 'Integration',
    sectionIcon: 'code',
    type: 'string',
  },
  desktop_scale_cls: {
    name: 'Desktop Resize skin (CLS)',
    section: 'Integration',
    sectionIcon: 'code',
    type: 'string',
  },
  use_desktop_integration: {
    name: 'Use Desktop Integration',
    section: 'Integration',
    sectionIcon: 'code',
    type: 'boolean',
  },
  use_cls_tablet: {
    name: 'Use CLS Tablet',
    section: 'Integration',
    sectionIcon: 'code',
    type: 'boolean',
  },
  tablet_status: {
    name: 'Tablet OneSkin Ready',
    section: 'Integration',
    sectionIcon: 'code',
    type: 'boolean',
  },
  tablet_html: {
    name: 'Tablet HTML',
    section: 'Integration',
    sectionIcon: 'code',
    type: 'code',
  },
  tablet_html_cls: {
    name: 'Tablet HTML (CLS)',
    section: 'Integration',
    sectionIcon: 'code',
    type: 'code',
  },
  tablet_scale: {
    name: 'Tablet Resize skin',
    section: 'Integration',
    sectionIcon: 'code',
    type: 'string',
  },
  tablet_scale_cls: {
    name: 'Tablet Resize skin (CLS)',
    section: 'Integration',
    sectionIcon: 'code',
    type: 'string',
  },
  use_cls_mobile: {
    name: 'Use CLS Mobile',
    section: 'Integration',
    sectionIcon: 'code',
    type: 'boolean',
  },
  mobile_status: {
    name: 'Mobile OneSkin Ready',
    section: 'Integration',
    sectionIcon: 'code',
    type: 'boolean',
  },
  mobile_js: {
    name: 'Javascript: Mobile Mskin, PageSkin, TopRoll',
    section: 'Integration',
    sectionIcon: 'code',
    type: 'code',
  },
  mobile_js_cls: {
    name: 'Javascript: Mobile Mskin, PageSkin, TopRoll (CLS)',
    section: 'Integration',
    sectionIcon: 'code',
    type: 'code',
  },
  mobile_css: {
    name: 'Css: Mobile Mskin, TopRoll, PageSkin',
    section: 'Integration',
    sectionIcon: 'code',
    type: 'code',
  },
  mobile_css_cls: {
    name: 'Css: Mobile Mskin, TopRoll, PageSkin (CLS)',
    section: 'Integration',
    sectionIcon: 'code',
    type: 'code',
  },
  hostnames_zones: {
    name: 'Hostnames',
    section: 'Oneskin settings',
    type: 'compared-list',
  },
  adserving_ads: {
    name: 'Adserving',
    section: 'Adserving',
    sectionIcon: 'api',
    type: 'compared-list',
  },
  connectors_ads: {
    name: 'Connectors',
    section: 'Connectors',
    sectionIcon: 'api',
    type: 'compared-list',
  },
  external_zone_placements: {
    name: "External Id's",
    section: 'Oneskin settings',
    type: 'compared-list',
  },
  prebid_prebidjs_global: {
    name: 'Prebid JS global',
    section: 'Header Bidding',
    sectionIcon: 'auction',
    sectionIconSets: 'bootstrapIcon',
    type: 'string',
  },
  prebid_activated: {
    name: 'Prebid Activated',
    section: 'Header Bidding',
    sectionIcon: 'auction',
    sectionIconSets: 'bootstrapIcon',
    type: 'string',
  },
  'prebid_bid_sizes.desktop.height': {
    name: 'Prebid Desktop Height',
    section: 'Header Bidding',
    sectionIcon: 'auction',
    sectionIconSets: 'bootstrapIcon',
    type: 'string',
  },
  'prebid_bid_sizes.desktop.width': {
    name: 'Prebid Desktop Width',
    section: 'Header Bidding',
    sectionIcon: 'auction',
    sectionIconSets: 'bootstrapIcon',
    type: 'string',
  },
  'prebid_bid_sizes.mobile.height': {
    name: 'Prebid Mobile Height',
    section: 'Header Bidding',
    sectionIcon: 'auction',
    sectionIconSets: 'bootstrapIcon',
    type: 'string',
  },
  'prebid_bid_sizes.mobile.width': {
    name: 'Prebid Mobile Width',
    section: 'Header Bidding',
    sectionIcon: 'auction',
    sectionIconSets: 'bootstrapIcon',
    type: 'string',
  },
  prebid_ssp_conf_improve: {
    name: 'Prebid Improve activated',
    section: 'Header Bidding',
    sectionIcon: 'auction',
    sectionIconSets: 'bootstrapIcon',
    type: 'string',
  },
  prebid_ssp_conf_smartadserver: {
    name: 'Prebid SmartAdserver activated',
    section: 'Header Bidding',
    sectionIcon: 'auction',
    sectionIconSets: 'bootstrapIcon',
    type: 'string',
  },
  prebid_ssp_conf_appnexus: {
    name: 'Prebid Appnexus activated',
    section: 'Header Bidding',
    sectionIcon: 'auction',
    sectionIconSets: 'bootstrapIcon',
    type: 'string',
  },
  'buycard.skinz-desktop': {
    name: 'Buycard of placement skinz desktop',
    section: 'Buycard',
    sectionIcon: 'currencies',
    sectionIconSets: 'bootstrapIcon',
    type: 'string',
  },
  'buycard.skinz-mobile': {
    name: 'Buycard of placement skinz mobile',
    section: 'Buycard',
    sectionIcon: 'currencies',
    sectionIconSets: 'bootstrapIcon',
    type: 'string',
  },
  'buycard.skinroll-desktop': {
    name: 'Buycard of placement skinroll desktop',
    section: 'Buycard',
    sectionIcon: 'currencies',
    sectionIconSets: 'bootstrapIcon',
    type: 'string',
  },
  'buycard.skinroll-mobile': {
    name: 'Buycard of placement skinroll mobile',
    section: 'Buycard',
    sectionIcon: 'currencies',
    sectionIconSets: 'bootstrapIcon',
    type: 'string',
  },
  'buycard.inkroll-desktop': {
    name: 'Buycard of placement inkroll desktop',
    section: 'Buycard',
    sectionIcon: 'currencies',
    sectionIconSets: 'bootstrapIcon',
    type: 'string',
  },
  'buycard.inkroll-mobile': {
    name: 'Buycard of placement inkroll mobile',
    section: 'Buycard',
    sectionIcon: 'currencies',
    sectionIconSets: 'bootstrapIcon',
    type: 'string',
  },
  'buycard.infeed-mobile': {
    name: 'Buycard of placement infeed mobile',
    section: 'Buycard',
    sectionIcon: 'currencies',
    sectionIconSets: 'bootstrapIcon',
    type: 'string',
  },
  'buycard.bottom-mobile': {
    name: 'Buycard of placement bottom mobile',
    section: 'Buycard',
    sectionIcon: 'currencies',
    sectionIconSets: 'bootstrapIcon',
    type: 'string',
  },
  'buycard.toproll-mobile': {
    name: 'Buycard of placement toproll mobile',
    section: 'Buycard',
    sectionIcon: 'currencies',
    sectionIconSets: 'bootstrapIcon',
    type: 'string',
  },
};

export const campaignPeriods = [
  {
    label: 'All',
    value: 'all',
  },
  {
    label: 'Current',
    value: 'current',
  },
  {
    label: 'Incoming',
    value: 'incoming',
  },
  {
    label: 'Past',
    value: 'past',
  },
  {
    label: 'Permanent',
    value: 'permanent',
  },
];

export const scriptDetectError = js => {
  const regexScriptStart = /<script\b[^>]*>/;
  const regexScriptEnd = /<\/script>/;

  const scriptStart = regexScriptStart.exec(js);
  const scriptEnd = regexScriptEnd.exec(js);

  if (scriptStart || scriptEnd) {
    if (scriptStart && !scriptEnd) {
      return `Script tag detected at start of script.`;
    }
    if (scriptEnd && !scriptStart) {
      return `Script tag detected at end of script.`;
    }
    return `Script tag detected at start and end of script.`;
  }
  return null;
};

export const organizationIdToLogs = ['9a4ab000-a7b0-11e9-9962-53f134758ecb'];
