const connectorAdapter = c => {
  if (c.id && c.attributes) {
    return {
      id: c.id,
      ssp_name: c.attributes.ssp_name || c.attributes.ssp.name,
      ...(c.relationships?.campaign
        ? { campaign_name: c.relationships.campaign.attributes.name }
        : {}),
      ...c.attributes,
      relationships: {
        ...c.relationships,
        sspSync: c.relationships?.improve_sync
          ? {
              ...c.relationships.improve_sync,
              sspName: 'Improve',
            }
          : {},
      },
    };
  }
  return {};
};

export default connectorAdapter;
