import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import InfoBox from 'components/atoms/InfoBox';

import styles from './TosInfoBox.scss';

const cx = classNames.bind(styles);

const TosInfoBox = ({ status, className, position }) => {
  if (status === 'success') {
    return '';
  }

  return (
    <InfoBox
      className={cx('tos-infobox', {
        [className]: true,
        [`tos-infobox--${position}`]: true,
      })}
      variant={status === 'loading' ? 'warning' : 'danger'}
      title="Term of services"
      titleAs="inline"
      message={
        status === 'loading' ? 'Checking SBA et ToS...' : 'We have a problem connecting to TOS'
      }
      cloudy
    />
  );
};

TosInfoBox.propTypes = {
  className: PropTypes.string,
  status: PropTypes.string,
  position: PropTypes.string,
};

TosInfoBox.defaultProps = {
  className: '',
  status: 'loading',
  position: 'bottom-right',
};

export default TosInfoBox;
